export const GET_STORE_INVENTORY = 'GET_STORE_INVENTORY';

export interface IPayloadStoreInventoryRequest {
    skuId: string;
    radius: number;
    geolocation: {
        lat: number;
        lng: number;
    };
}

export interface Door {
    DOOR_ID: number;
    DOORNAME: string;
    STORE_HOURS: string;
    STORE_TYPE: string;
    ADDRESS: string;
    ADDRESS2: string;
    STATE_OR_PROVINCE: string;
    CITY: string;
    COUNTRY: string;
    PHONE1: string;
    LONGITUDE: string;
    LATITUDE: string;
    DISTANCE: string;
}

export interface StoreInventory {
    is_call: number;
    is_available: number;
    is_unavailable: number;
    available_qty: number;
}

export interface DoorInventory {
    store_code: number;
    skus_onhand: {
        [skuiId: string]: StoreInventory;
    };
}

export interface IPayloadStoreInventoryResponse {
    requestOptions: IPayloadStoreInventoryRequest;
    doors: number[];
    results: {
        [doorId: number]: Door;
    };
    door_inventory: {
        [doorId: number]: DoorInventory;
    };
}

export type ServiceStoreInventoryQueries = {
    [GET_STORE_INVENTORY]: IPayloadStoreInventoryRequest;
};

export type ServiceStoreInventoryQueriesResponseType = {
    [GET_STORE_INVENTORY]: Promise<IPayloadStoreInventoryResponse>;
};
