import { ICart } from '../events/CartInterfaces';

export const IS_CART_VALID = 'isCartValid';
export const CHOICE_OF_SKUS_ITEMS_COUNT = 'choiceOfSkusItemsCounts';
export const CART_ITEMS_SUBTOTAL = 'cart.items.subtotal';
export const CART_ITEMS_COUNT = 'cart.items.counter';
export const CART_DIGITAL_SIGNATURE = 'cart.digitalSignature';
export const SUCCESSFUL_OFFER_CODES = 'successful.offer.codes';
export const CART_SKUS_IDS = 'cart.skus.ids';
export const GET_CART = 'get.cart';
export const RECOMMENDED_PRODUCTS_SKUID_LIST = 'recommended.products.skuids.list';

export type ServiceCartQueries = {
    [IS_CART_VALID]: undefined;
    [CHOICE_OF_SKUS_ITEMS_COUNT]: undefined;
    [CART_ITEMS_SUBTOTAL]: undefined;
    [CART_ITEMS_COUNT]: undefined;
    [CART_DIGITAL_SIGNATURE]: undefined;
    [SUCCESSFUL_OFFER_CODES]: undefined;
    [CART_SKUS_IDS]: undefined;
    [GET_CART]: { asPlainText?: boolean };
    [RECOMMENDED_PRODUCTS_SKUID_LIST]: undefined;
};

export type ServiceCartQueriesResponseType = {
    [IS_CART_VALID]: boolean;
    [CHOICE_OF_SKUS_ITEMS_COUNT]: number;
    [CART_ITEMS_SUBTOTAL]: number;
    [CART_ITEMS_COUNT]: number;
    [CART_DIGITAL_SIGNATURE]: string;
    [SUCCESSFUL_OFFER_CODES]: string[];
    [CART_SKUS_IDS]: string[];
    [GET_CART]: ICart | string;
    [RECOMMENDED_PRODUCTS_SKUID_LIST]: string[];
};
