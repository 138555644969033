export const IS_LOYALTY_MEMBER = 'isLoyaltyMember';
export const LOYALTY_POINTS = 'points';

export type ServiceLoyaltyQueries = {
    [IS_LOYALTY_MEMBER]: undefined;
    [LOYALTY_POINTS]: undefined;
};

export type ServiceLoyaltyQueriesResponseType = {
    [IS_LOYALTY_MEMBER]: boolean;
    [LOYALTY_POINTS]: number;
};
