export const ORDER_SHIPPING_ADDRESS = 'order.shipping.address';
export const GET_ORDER_DATA = 'get.order.data';
export interface IAddressesAttributes {
    firstLineAddress: string;
    address: string;
    address2?: string;
    city: string;
    country: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    zipcode: string;
}

export interface IAddresses {
    addressType: string;
    id: string;
    attributes: IAddressesAttributes;
}

export interface ISelectedShippingDates {
    [key: string]: string[];
}

export interface IOrderDetail {
    order: {
        id: string;
        orderNumber: number;
        businessUnitId: string;
        created: Date;
        updated: Date;
        tcVersion: string;
        email: string;
        source: string;
        redirectUrl: string;
        status: {
            internal: string;
            external: string;
        };
        orderType: string;
        browserIP: string;
        testOrder: boolean;
        language: {
            id: string;
            name: string;
            isoCode: string;
        };
        currency: {
            id: string;
            name: string;
            isoCode: string;
        };
        addresses: IAddresses[];
        shippings: [
            {
                shippingAddress: {
                    id?: string;
                    attributes: {
                        [field: string]: string;
                    };
                    addressMetadataId: string;
                };
                shippingInfo: {
                    discountPercent: number;
                    discountedPriceWithTax: number;
                    discountedPriceWithoutTax: number;
                    offerCode: string | null;
                    priceWithTax: number;
                    priceWithoutTax: number;
                    tax: number;
                    selectedShippingDates: ISelectedShippingDates;
                };
                shippingMethod: {
                    id: string;
                    name: string;
                    skuId: string;
                    price: number;
                };
            }
        ];
        shippingInfo: [
            {
                priceWithTax: number; //price of shipping; taxes included
                priceWithoutTax: number; //price of shipping; no taxes included
                discountedPriceWithTax: number; //price of shipping after applying shipping discounts; taxes included
                discountedPriceWithoutTax: number; //price of shipping after applying shipping discounts; taxes included
                discountPercent: number; //the discount percent applied to the shipping price
                tax: number; //the taxes calculated for shipping
                offerCode: string; //shipping promotion offer code; if one exists
                addressId: string;
                shippingId: string;
            }
        ];
        items: [
            {
                id: string;
                skuId: string;
                quantity: number;
                unitPriceWithoutTax: number; //price of the single item; no taxes included
                unitPriceWithTax: number; //price of the single item; taxes included
                discountedUnitPriceWithoutTax: number; //price of the single item; all discounts subtracted; no taxes included
                discountedUnitPriceWithTax: number; //price of the single item; all discounts subtracted;  taxes included
                discountsPercent: number; //discount percent applied to price
                appliedPriceWithoutTax: number; //total price for this item (discountedPrice * quantity); no taxes included
                appliedPriceWithTax: number; //total price for this item (discountedPrice * quantity); taxes included
                taxAmount: number;
                productName: string;
                productImage: string;
                shades: string[];
                isHazmat: boolean;
                isShippable: boolean;
                tagId: string;
                tagName: string;
            }
        ];
        engraving: string[];
        giftwrap: string[];
        donation: {
            id: string;
            skuId: string;
        };
        totals: {
            subTotalWithTax: number; //cost of all items summed up (appliedPrice); taxes included
            subTotalWithoutTax: number; //cost of all items summed up (appliedPrice); no taxes included
            totalWithTax: number; //total price of the order(shipping; items; discounts; engraving etc); taxes included
            totalWithoutTax: number; //total price of the order(shipping; items; discounts; engraving etc); no taxes included
            totalDiscounts: number; //total discounts that have been applied
            cartDiscounts: number; //cart level discounts
            tax: number; //total taxes
            taxPercent: number; //tax percent that will be applied (VAT 16% for example)
            totalQuantity: number; //sum of quantity of all items
        };
        additionalInfo: Map<string, string>;
    };
    billingAddresses: {
        [id: string]: {
            metadataId: string;
            attributes: {
                [field: string]: string;
            };
        };
    };
    orderEvents: [
        {
            orderId: string;
            ownerId: string;
            produced: Date;
            recorded: Date;
            source: string;
            orderDetails: string; // see https://confluence.esteeonline.com/display/ELP/Order+Events+Service for specific event models
        }
    ];
    paymentAmounts: {
        pending: number[]; // the amounts of the pending transactions
        declined: number[]; // the amounts of the declined transactions
        authorized: number[]; // the amounts of the authorized transactions (not captured)
        captured: number[]; // the amounts of the captured transactions
        cancelled: number[]; // the amounts of the (PP side) cancelled transactions
    };
    balance: {
        pending: number; //  Order total value MINUS the value of ALL payment transactions authorized; captured or pending
        authorized: number; // Order total value MINUS the value of ALL authorized and captured transactions
        captured: number; // Order total value MINUS the value of ALL captured transactions
    };
    deliveryStatus: [
        {
            itemId: string;
            trackingNumber: string;
            deliveredQuantity: number;
            remainingQuantity: number;
            backOrderNumber: string;
            deliveryDate: string; // string representing a date in ISO8601 format
            carrier: string;
        }
    ];
    paymentMethods: string[];
}

export interface IOrderQueryData {
    orderId: string;
    guestToken: string;
    paymentType: string;
}
export type ServiceOrderQueries = {
    [ORDER_SHIPPING_ADDRESS]: undefined;
    [GET_ORDER_DATA]: IOrderQueryData;
};

export interface ISharedAddressAttributes {
    [field: string]: string;
}
export interface ISharedAddress {
    addressMetadataId: string;
    attributes: ISharedAddressAttributes;
}

export type ServiceOrderQueriesResponseType = {
    [ORDER_SHIPPING_ADDRESS]: ISharedAddress;
    [GET_ORDER_DATA]: Promise<IOrderDetail>;
};
