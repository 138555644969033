export const FIRST_ORDER_DATE = 'first.order.date';
export const FIRST_ORDER_VALUE = 'first.order.value';
export const LAST_ORDER_DATE = 'last.order.date';
export const LAST_ORDER_VALUE = 'last.order.value';
export const ORDERS_AVG = 'orders.avg';
export const TOTAL_COUNT_OF_ORDERS = 'total.count.of.orders';
export const TOTAL_SUM_OF_REVENUE = 'total.sum.of.revenue';
export interface IStatus {
    external: string;
    internal: string;
}
export interface ITotals {
    subTotalWithTax: number;
    subTotalWithoutTax: number;
    totalWithTax: number;
    totalWithoutTax: number;
    totalDiscounts: number;
    cartDiscounts: number;
    tax: number;
    taxPercent: number;
    totalQuantity: number;
}
export interface IDeliveryStatus {
    backOrderNumber: string;
    carrier: string;
    deliveryQuantity: number;
    deliveryDate: string;
    itemId: string;
    remainingQuantity: number;
    trackingNumber: string;
    trackingUrl?: string;
}
export interface IAddresses {
    addressType: string;
    id: string;
    attributes: IAttributes;
}
export interface IAttributes {
    firstName: string;
    lastName: string;
    address: string;
    zipcode: string;
    city: string;
    mobileNumber: string;
}
export interface IOrder {
    orderId: string;
    orderNumber: number;
    businessUnitId: string;
    created: string;
    ownerId: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any;
    status: IStatus;
    totals: ITotals;
    email: string;
    deliveryStatuses: IDeliveryStatus[];
    addresses: IAddresses[];
}

export interface IOrderHistoryCollection {
    orderHistoryData: IOrder[];
    temporaryOrders: IOrder[];
    temporaryOrdersLength: number;
    firstOrderDate: string | null;
    firstOrderValue: number;
    lastOrderDate: string | null;
    lastOrderValue: number;
    ordersAvg: number;
    totalSumOfRevenue: number;
}

export type ServiceOrderHistoryQueries = {
    [FIRST_ORDER_DATE]: undefined;
    [FIRST_ORDER_VALUE]: undefined;
    [LAST_ORDER_DATE]: undefined;
    [LAST_ORDER_VALUE]: undefined;
    [ORDERS_AVG]: undefined;
    [TOTAL_COUNT_OF_ORDERS]: undefined;
    [TOTAL_SUM_OF_REVENUE]: undefined;
};

export type ServiceOrderHistoryQueriesResponseType = {
    [FIRST_ORDER_DATE]: string | null;
    [FIRST_ORDER_VALUE]: number;
    [LAST_ORDER_DATE]: number;
    [LAST_ORDER_VALUE]: number;
    [ORDERS_AVG]: number;
    [TOTAL_COUNT_OF_ORDERS]: number;
    [TOTAL_SUM_OF_REVENUE]: number;
};
