export const CHANGE_ROUTE_PROFILE = 'change.route.profile';
export const CHANGE_ROUTE_SETTINGS = 'change.route.settings';
export const CHANGE_ROUTE_ORDER_HISTORY = 'change.route.orderHistory';

export type ServiceElcEcommCommands = {
    [CHANGE_ROUTE_PROFILE]: undefined;
    [CHANGE_ROUTE_SETTINGS]: undefined;
    [CHANGE_ROUTE_ORDER_HISTORY]: undefined;
};

export type ServiceElcEcommCommandsReturnType = {
    [CHANGE_ROUTE_PROFILE]: undefined;
    [CHANGE_ROUTE_SETTINGS]: undefined;
    [CHANGE_ROUTE_ORDER_HISTORY]: undefined;
};
