export const LEGAL_ACCEPTANCE_TOKEN = 'legal.acceptance.token';

/**
 * Specification for getting a legal acceptance token
 */
export interface IGetLegalAcceptanceSpec {
    /**
     * The action name
     */
    action: string;

    /**
     * If true this is a request for a guest
     */
    guest?: boolean;

    /**
     * The owner for which the token is updated/created for
     */
    owner?: string;
    /**
     * The text the user sees in order to acknowledge the legal implications.
     * Ex: By pressing 'Submit' you agree with the Terms and Conditions.
     */
    acceptanceTag: string;
}

export type ServiceLegalQueriesResponseType = {
    [LEGAL_ACCEPTANCE_TOKEN]: Promise<string>;
};

export type ServiceLegalQueries = {
    /**
     * Gets the legal acceptance query for a given action
     */
    [LEGAL_ACCEPTANCE_TOKEN]: IGetLegalAcceptanceSpec;
};
