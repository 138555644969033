export const CARE_NAVIGATE_SIGN_IN = 'care.navigate.signIn';
export const CARE_NAVIGATE_SIGN_UP = 'care.navigate.signUp';
export const CARE_NAVIGATE_PASSWORD_FORGOT = 'care.navigate.passwordForgot';
export const CARE_NAVIGATE_PASSWORD_RESET_LINK_SENT = 'care.navigate.passwordResetLinkSent';

export type CareEvents = {
    [CARE_NAVIGATE_SIGN_IN]: undefined;
    [CARE_NAVIGATE_SIGN_UP]: undefined;
    [CARE_NAVIGATE_PASSWORD_FORGOT]: undefined;
    [CARE_NAVIGATE_PASSWORD_RESET_LINK_SENT]: undefined;
};
