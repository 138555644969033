export const UPDATE_INVENTORY = 'update.inventory';

interface IInventoryStatus {
    inventory_status: number | string;
}

export interface IInventoryStatuses {
    [key: string]: IInventoryStatus;
}

export type ServiceProdcatCommands = {
    [UPDATE_INVENTORY]: IInventoryStatuses;
};

export type ServiceProdcatCommandsReturnType = {
    [UPDATE_INVENTORY]: undefined;
};
