export const RAMP_SIGN_UP_SUBMITTED = 'ramp.signup.submitted';
export const RAMP_SIGN_UP_SUCCESSFUL = 'ramp.signup.successful';
export const RAMP_SIGN_UP_FAILED = 'ramp.signup.failed';
export const RAMP_LOG_IN_SUBMITTED = 'ramp.login.submitted';
export const RAMP_LOG_IN_SUCCESSFUL = 'ramp.login.successful';
export const RAMP_LOG_IN_FAILED = 'ramp.login.failed';

export type RampEvents = {
    [RAMP_SIGN_UP_SUBMITTED]: {};
    [RAMP_SIGN_UP_SUCCESSFUL]: {};
    [RAMP_SIGN_UP_FAILED]: {};
    [RAMP_LOG_IN_SUBMITTED]: {};
    [RAMP_LOG_IN_SUCCESSFUL]: {};
    [RAMP_LOG_IN_FAILED]: {};
};
