export const UPDATE_POWER_REVIEWS_CONFIGS = 'updateReviewConfig';

export interface IReviewUpdateConfig {
    apiKey: string;
    merchantGroupId: string;
    merchantId: string;
    locale: string;
}

export type ServiceRatingsReviewsCommands = {
    [UPDATE_POWER_REVIEWS_CONFIGS]: IReviewUpdateConfig;
};

export type ServiceRatingsReviewsCommandsReturnType = {
    [UPDATE_POWER_REVIEWS_CONFIGS]: Promise<void>;
};
