import { ELCLogger, TriggerType } from '@estee/elc-logging';
import { root } from '@estee/elc-universal-utils';

/* eslint-disable @typescript-eslint/no-explicit-any */

const { name, version } = __serviceInfo__;
export interface IQueryProvider {
    queryName: string;
    provider: Function;
    context: any;
}
interface IQueryWatcher {
    queryName: string;
    queryWatcher: Function;
}

type QueryCacheType = { [k: string]: boolean | number | string };
export interface IUnsolvedQuery<Q> {
    promise: Promise<any>;
    resolve: Function;
    payload: any;
    queryName: keyof Q;
}

export type OptionsType = {
    replay?: boolean;
};

type EventListener = Function;
type NextFnType = Function;
type PayloadType = any;
interface IHook {
    beforeEventListenerRegister?(event: string, listener?: EventListener, next?: NextFnType): void;
    beforeEmit?(action: string, payload?: PayloadType, next?: NextFnType): void;
    afterEmit?(event: string, payload?: PayloadType): void;
    beforeQueryRegister?(queryName: string, qp: IQueryProvider, next: NextFnType): void;
    beforeQuery?(queryName: string, payload?: PayloadType, next?: NextFnType): void;
    afterQuery?(queryName: string, payload?: PayloadType): void;
}
type HooksFunction = <E, Q, QR, C, CR>(bus: ServiceBus<E, Q, QR, C, CR>) => IHook;
type HooksCollection = { [k: string]: Function[] };

type LogType = 'info' | 'error';
export const LogTypes = {
    info: 'info' as LogType,
    error: 'error' as LogType
};

export class ServiceBus<E, Q, QR, C, CR> {
    private eventWatchers: Map<any, Function[]>;
    private eventsEmitted: Map<any, any>;
    private queryProviders: IQueryProvider[];
    private queryWatchers: IQueryWatcher[];
    private queryCache: QueryCacheType;
    private unsolvedQueries: IUnsolvedQuery<Q>[] = [];
    private hooks: HooksCollection = {};

    protected readonly logger = new ELCLogger({
        serviceName: name,
        serviceVersion: version,
        environment: root.env,
        buid: root.buid
    });

    private dummyHook = (event: string, listener?: EventListener, next?: NextFnType): void =>
        next && next(event, listener);

    constructor() {
        this.eventWatchers = new Map();
        this.eventsEmitted = new Map();
        this.queryProviders = [];
        this.queryWatchers = [];
        this.queryCache = {};
        // Hooks form a queue, in which the original function is the last in the queue
        this.hooks = {
            beforeEmit: [this.runEmit],
            beforeQuery: [this.runQuery],
            beforeEventListenerRegister: [this.dummyHook],
            beforeQueryRegister: [this.runRegisterQueryProvider]
        };
        setInterval(this.dirtyCheckQueries, 200);
    }

    public log = ({
        message,
        payload,
        type = LogTypes.info as LogType
    }: {
        message: string;
        payload?: { error?: Error; context?: Record<string, unknown> };
        type?: LogType;
    }) => {
        const logMethod = type === LogTypes.error ? this.logger.error : this.logger.info;

        logMethod({
            message,
            payload,
            triggerType: TriggerType.serviceBus
        });
    };

    public on = <K extends keyof E>(
        eventName: K,
        callback: (payload: E[K]) => any,
        options?: OptionsType
    ) => {
        this.runHooks('beforeEventListenerRegister', eventName, callback);
        this.runOn(eventName, callback, options);
    };

    private runOn = <K extends keyof E>(
        eventName: K,
        callback: (payload: E[K]) => any,
        options: OptionsType = {}
    ) => {
        const { replay = false } = options;

        if (!eventName) {
            const error = new Error(
                'Event name cannot be undefined while subscribing to ServiceBus'
            );
            this.logger.error({
                message: error.message,
                triggerType: TriggerType.serviceBus,
                payload: {
                    error
                }
            });
            throw error;
        }
        if (!callback) {
            const error = new Error('A callback is necessary while subscribing to ServiceBus');
            this.logger.error({
                message: error.message,
                triggerType: TriggerType.serviceBus,
                payload: {
                    error,
                    context: {
                        eventName
                    }
                }
            });
            throw error;
        }

        let callbacks = this.eventWatchers.get(eventName);

        if (!callbacks) {
            callbacks = [];
            this.eventWatchers.set(eventName, callbacks);
        }
        if (replay && this.eventsEmitted.has(eventName)) {
            callback(this.eventsEmitted.get(eventName));
        }
        if (callbacks) {
            const idx = callbacks.indexOf(callback);
            if (idx < 0) {
                callbacks.push(callback);
            }
        }
    };
    public off = (eventName: any, callback?: Function) => {
        if (callback) {
            const callbacks = this.eventWatchers.get(eventName);

            if (callbacks) {
                const idx = callbacks.indexOf(callback);
                if (idx >= 0) {
                    callbacks.splice(idx, 1);

                    if (callbacks.length === 0) {
                        this.eventWatchers.delete(eventName);
                    }
                }
            }
        } else {
            this.eventWatchers.delete(eventName);
        }
    };

    public applyHook = (hook: HooksFunction) => {
        const hookObj = hook(this as ServiceBus<E, Q, QR, C, CR>);
        Object.keys(hookObj).forEach((hookName: keyof IHook) => {
            const hookFn = hookObj[hookName] as any;
            this.hooks[hookName] = this.hooks[hookName] || [];
            const hkCol = this.hooks[hookName];
            if (hookFn && Array.isArray(hkCol)) {
                hkCol.unshift(hookFn);
            }
            this.solveQueries(hookName);
        });
    };

    private solveQueries = (hookEvent: string) => {
        if (hookEvent === 'beforeQuery') {
            if (this.hasUnsolvedQueries()) {
                this.unsolvedQueries.forEach((unsolvedQuery: IUnsolvedQuery<Q>) => {
                    const { resolve, queryName, payload } = unsolvedQuery;
                    const hooksPromise = this.runHooks(hookEvent, queryName, payload);
                    const isPromise = (potentialPromise: any) =>
                        potentialPromise && potentialPromise.then;

                    return isPromise(hooksPromise)
                        ? hooksPromise.then((results: any) => {
                              resolve(results);
                              this.unsolvedQueries = this.unsolvedQueries.filter(
                                  (query) => query !== unsolvedQuery
                              );
                          })
                        : undefined;
                });
            }
        }
    };

    public hasUnsolvedQueries = () => {
        return this.unsolvedQueries.length !== 0;
    };

    public emit = <K extends keyof E>(eventName: K, payload?: E[K]) => {
        this.runHooks('beforeEmit', eventName, payload);
    };

    private runEmit = (eventName: string, payload: any) => {
        const eventWatchers = this.eventWatchers.get(eventName);
        this.eventsEmitted.set(eventName, payload);
        if (eventWatchers) {
            eventWatchers.forEach((c) => {
                c(payload);
            });
        }
        this.runHooks('afterEmit', eventName, payload);
    };

    public query = <K extends keyof Q & keyof QR>(queryName: K, payload?: Q[K]): QR[K] =>
        this.runHooks('beforeQuery', queryName, payload);

    public command = <K extends keyof C & keyof CR>(commandName: K, payload?: C[K]): CR[K] =>
        this.runHooks('beforeQuery', commandName, payload);

    private runQuery = <K extends keyof Q>(queryName: K, payload?: Q[K]) => {
        const qp = this.queryProviders.find((p) => p.queryName === queryName);
        if (!qp) {
            let resolveFn = function runQueryResolveFn(value: unknown) {};
            const queryPromise = new Promise((resolve) => {
                resolveFn = resolve;
            });

            this.unsolvedQueries.push({
                promise: queryPromise,
                resolve: resolveFn,
                payload,
                queryName
            });

            return queryPromise;
        }

        return Promise.resolve(qp.provider.call(qp.context, payload)).then((r) =>
            this.afterQuery(qp, r, queryName as string)
        );
    };

    private afterQuery = (qp: IQueryProvider, result: any, queryName: string) => {
        this.runHooks('afterQuery', qp, result);

        return result;
    };

    public registerQueryProvider = <K extends keyof Q & keyof QR>(
        queryName: K,
        provider: (payload: Q[K]) => QR[K],
        context?: Record<string, any>
    ) => {
        return this.runHooks('beforeQueryRegister', queryName, provider, context);
    };
    public registerCommandProvider = <K extends keyof C>(
        commandName: K,
        provider: Function,
        context?: Record<string, any>
    ) => {
        return this.runHooks('beforeQueryRegister', commandName, provider, context);
    };
    private runRegisterQueryProvider = (
        queryName: string,
        provider: Function,
        context?: Record<string, any>
    ) => {
        const existingQueryProviders = this.queryProviders.filter(
            (qp) => qp.queryName === queryName
        );
        if (existingQueryProviders.length) {
            const error = new Error(
                `Trying to register a query providerm but there is already a provider for query ${queryName}`
            );
            this.logger.warning({
                message: error.message,
                triggerType: TriggerType.serviceBus,
                payload: {
                    error,
                    context
                }
            });

            return;
        }
        this.queryProviders.push({ queryName, provider, context });

        const unsolvedProviderQueries = this.unsolvedQueries.filter(
            (uq) => uq.queryName === queryName
        );
        if (unsolvedProviderQueries.length) {
            unsolvedProviderQueries.forEach(this.executeUnsolvedQuery);
            this.unsolvedQueries = this.unsolvedQueries.filter((uq) => uq.queryName !== queryName);
        }
    };

    public registerQueryWatcher = (queryName: any, queryWatcher: Function) => {
        this.queryWatchers.push({ queryName, queryWatcher });
        if (queryName in this.queryCache) {
            queryWatcher.call(null, this.queryCache[queryName]);
        }
    };

    private runHooks = (hookEvent: keyof IHook, ...args: any[]) => {
        const hookChain = this.hooks[hookEvent] || [];

        return this.executeHook(hookChain.slice(0), args);
    };
    private executeHook(hookChain: (Function | undefined)[], args: any) {
        const hookFn = hookChain.shift();
        if (typeof hookFn === 'function') {
            const isLastHook = hookChain.length === 0;
            const next = () => this.executeHook(hookChain.slice(0), args);
            const hookArgs = !isLastHook ? args.concat([next]) : args;

            return hookFn(...hookArgs);
        }
    }
    private executeUnsolvedQuery = ({ queryName, payload, resolve }: IUnsolvedQuery<Q>) => {
        const qp = this.queryProviders.find((p) => p.queryName === queryName);
        if (qp) {
            return Promise.resolve(qp.provider.call(qp.context, payload)).then((r: any) => {
                resolve(this.afterQuery(qp, r, queryName as string));

                return r;
            });
        }
    };
    private dirtyCheckQueries = () => {
        const localQueryCache: QueryCacheType = {};
        this.queryWatchers.forEach((qw) => {
            const { queryName, queryWatcher } = qw;
            const qp = this.queryProviders.find((p) => p.queryName === queryName);
            const queryCache = this.queryCache;
            if (qp) {
                const resultPromise = Promise.resolve(
                    localQueryCache[queryName] || qp.provider.call(qp.context)
                );
                void resultPromise.then((result) => {
                    if (result !== queryCache[queryName]) {
                        queryWatcher.call(null, result);
                    }
                    localQueryCache[queryName] = result;
                });
            }
        });
        this.queryCache = localQueryCache;
    };
}
