export const PAYMENT_SUBMITTING = 'payment.submitting';
export const SHIPPING_SCREEN_SUBMITTING = 'shipping.screen.submitting';
export const BACK_HEADER_CLICKED = 'back.header.clicked';
export const EXTERNAL_PAYMENT_ERROR_RESTORE_PROCESSED = 'external.payment.error.restore.processed';
export const CHECKOUT_SESSION_SAVED = 'checkout.session.saved';

export interface IPaymentSubmitting {
    orderId: string;
    orderNumber: string;
    guestToken: string;
    amount: number;
    billingAddress: IAddress;
}

export interface IAddress {
    addressMetadataId: string;
    attributes: {
        [key: string]: string;
    };
}

export interface IExternalPaymentErrorRestoreProcessedEventData {
    success: boolean;
}

export type ServiceCheckoutEvents = {
    [PAYMENT_SUBMITTING]: IPaymentSubmitting;
    [SHIPPING_SCREEN_SUBMITTING]: undefined;
    [BACK_HEADER_CLICKED]: undefined;
    [EXTERNAL_PAYMENT_ERROR_RESTORE_PROCESSED]: IExternalPaymentErrorRestoreProcessedEventData;
    [CHECKOUT_SESSION_SAVED]: undefined;
};
