export const CREATE_ACCOUNT_ATTEMPT = 'create.account.attempt';
export const CREATE_ACCOUNT_FAIL = 'create.account.fail';
export const CHANGE_EMAIL_LINK_CLICKED = 'change.email.link.clicked';
export const REGISTRATION_CONFIRMED = 'registration.confirmed';
export const SIGN_IN_LINK_CLICKED = 'sign.in.link.clicked';

export interface IPayloadSignInClick {
    urlRedirectOnSuccess?: string;
}
export type ServiceSignUpEvents = {
    [CREATE_ACCOUNT_ATTEMPT]: {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [CREATE_ACCOUNT_FAIL]: any;
    [CHANGE_EMAIL_LINK_CLICKED]: undefined;
    [REGISTRATION_CONFIRMED]: undefined;
    [SIGN_IN_LINK_CLICKED]: IPayloadSignInClick | void;
};
