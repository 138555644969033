// TODO: remove this once all sites are on elc-serivce-analytics@3.0
export const CONFIG_ANALYTICS = 'config.analytics';
export const ANALYTICS_LOADED = 'analytics.loaded';
export const ANALYTICS_PRODUCTS_VIEWED = 'analytics.products.viewed';
export const ANALYTICS_PRODUCT_VIEWED = 'analytics.product.viewed';
export const ANALYTICS_PAGE_VIEWED = 'analytics.page.viewed';

export interface IConfigAnalytics {
    enabled: boolean;
}

export interface IProductsViewed {
    skuIds?: string[];
    productIds?: string[];
    productTags?: string[];
}

export interface IProductViewed {
    skuId?: string;
    productId?: string;
}

export type ServiceAnalyticsEvents = {
    [CONFIG_ANALYTICS]: IConfigAnalytics;
    [ANALYTICS_LOADED]: undefined;
    [ANALYTICS_PRODUCTS_VIEWED]: IProductsViewed;
    [ANALYTICS_PRODUCT_VIEWED]: IProductViewed;
    [ANALYTICS_PAGE_VIEWED]: undefined;
};
