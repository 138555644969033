export const ORDER_CREATE = 'order.create';
export const ORDER_CHECK = 'order.check';
export const ORDER_UPDATE = 'order.update';

export type ServiceOrderCommands = {
    [ORDER_CREATE]: undefined;
    [ORDER_UPDATE]: undefined;
};

export type ServiceOrderCommandsReturnType = {
    [ORDER_CREATE]: Promise<void>;
    [ORDER_UPDATE]: undefined;
};
