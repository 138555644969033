import * as ElcEcommCommands from './fe-elc-ecomm';
import * as ElcServiceCartCommands from './fe-elc-service-cart';
import * as ElcServiceProdcatCommands from './fe-elc-service-prodcat';
import * as ElcServiceRatingsReviewsCommands from './fe-elc-service-ratings-reviews';
import * as ElcServiceSignInCommands from './fe-elc-service-sign-in';
import * as ElcServiceUserProfileCommands from './fe-elc-service-user-profile';
import * as ElcServiceOrderCommands from './fe-elc-service-order';

const serviceCommands = {
    ...ElcEcommCommands,
    ...ElcServiceCartCommands,
    ...ElcServiceOrderCommands,
    ...ElcServiceProdcatCommands,
    ...ElcServiceRatingsReviewsCommands,
    ...ElcServiceSignInCommands,
    ...ElcServiceUserProfileCommands
};

type ServiceCommands = ElcServiceCartCommands.ServiceCartCommands &
    ElcEcommCommands.ServiceElcEcommCommands &
    ElcServiceOrderCommands.ServiceOrderCommands &
    ElcServiceProdcatCommands.ServiceProdcatCommands &
    ElcServiceRatingsReviewsCommands.ServiceRatingsReviewsCommands &
    ElcServiceSignInCommands.ServiceSignInCommands &
    ElcServiceUserProfileCommands.ServiceUserProfileCommands;

type ServiceCommandsReturnType = ElcServiceCartCommands.ServiceCartCommandsReturnType &
    ElcEcommCommands.ServiceElcEcommCommandsReturnType &
    ElcServiceOrderCommands.ServiceOrderCommandsReturnType &
    ElcServiceProdcatCommands.ServiceProdcatCommandsReturnType &
    ElcServiceRatingsReviewsCommands.ServiceRatingsReviewsCommandsReturnType &
    ElcServiceSignInCommands.ServiceSignInCommandsReturnType &
    ElcServiceUserProfileCommands.ServiceUserProfileCommandsReturnType;

// eslint-disable-next-line no-restricted-syntax
export { serviceCommands, ServiceCommands, ServiceCommandsReturnType };
