export const GET_APPT_BOOKING_ACCESS_TOKEN = 'apptBooking.tokenAccess.get';
export const GET_APPT_BOOKING_COUNTERS = 'apptBooking.counters.get';
export const GET_APPT_BOOKING_CONTENTS_TEMPLATE = 'apptBooking.contentTemplates.get';

export interface ICounter {
    id: number;
    name: string;
    serviceIds: number[];
    counterLat: number;
    counterLng: number;
    town: string;
    address1: string;
    address2: string;
    phone: string;
    timeslotInterval: number;
    calendars: IApiCalendar[];
    maxAppointmentServices: number;
}

export interface IApptBookingServiceContentWrapper {
    template_fields: IApptBookingServiceContentTemplate;
}

export interface IApptBookingServiceContentTemplate {
    data: IApptBookingServiceContent;
}

export interface IApptBookingServiceContent {
    image: IImage;
    description: string;
    contentId: string;
    title: string;
}

export interface IImage {
    src: string;
    alt: string;
}

export interface IApiTimeSlots {
    start_date: string;
    end_date: string;
}

export interface IApiCalendar {
    timeslots: IApiTimeSlots[];
}

export type ServiceApptBookingQueries = {
    [GET_APPT_BOOKING_ACCESS_TOKEN]: {};
    [GET_APPT_BOOKING_COUNTERS]: {};
    [GET_APPT_BOOKING_CONTENTS_TEMPLATE]: {};
};

export type ServiceApptBookingQueriesResponseType = {
    [GET_APPT_BOOKING_ACCESS_TOKEN]: string;
    [GET_APPT_BOOKING_COUNTERS]: ICounter[];
    [GET_APPT_BOOKING_CONTENTS_TEMPLATE]: IApptBookingServiceContentWrapper[];
};
