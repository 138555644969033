export const ACCOUNT_SIDEBAR_OVERLAY_OPENED = 'accountSidebar.overlay.opened';
export const ACCOUNT_SIDEBAR_MENU_LINK_CLICKED = 'accountSidebar.menuLinkClicked';
export const ACCOUNT_SIDEBAR_LOYALTY_LINK_CLICKED = 'accountSidebar.loyaltyLinkClicked';
export const ACCOUNT_SIDEBAR_SHOWCASE_LINK_CLICKED = 'accountSidebar.showcaseLinkClicked';
export const LOYALTY_ACCOUNT_ENROLMENT = 'account.loyaltyEnrolment';
export const ACCOUNT_LOYALTY_POINTS_INFO_LINK_CLICKED = 'accountLoyalty.pointsInfoLinkClicked';
export const ACCOUNT_NONLOYALTY_ENROLLMENT_SIGNUP_CLICKED =
    'accountNonLoyalty.EnrollmentSignupClicked';
export const ACCOUNT_NONLOYALTY_ENROLLMENT_LINK_CLICKED = 'accountNonLoyalty.EnrollmentLinkClicked';

export interface IAccountSidebarBasePayload {
    label: string;
}

export interface IAccountSidebarShowcasePayload {
    key?: string;
    item_title?: string;
    item_teaser?: string;
    link_label?: string;
    link_url?: string;
}

export interface ILoyaltyAccountEnrolment {
    loyaltyFlag?: boolean | null;
}

export type ServiceAccountEvents = {
    [ACCOUNT_SIDEBAR_OVERLAY_OPENED]: void | undefined;
    [ACCOUNT_SIDEBAR_MENU_LINK_CLICKED]: IAccountSidebarBasePayload;
    [ACCOUNT_SIDEBAR_LOYALTY_LINK_CLICKED]: IAccountSidebarBasePayload;
    [ACCOUNT_SIDEBAR_SHOWCASE_LINK_CLICKED]: IAccountSidebarShowcasePayload;
    [LOYALTY_ACCOUNT_ENROLMENT]: ILoyaltyAccountEnrolment;
    [ACCOUNT_LOYALTY_POINTS_INFO_LINK_CLICKED]: IAccountSidebarBasePayload;
    [ACCOUNT_NONLOYALTY_ENROLLMENT_SIGNUP_CLICKED]: ILoyaltyAccountEnrolment;
    [ACCOUNT_NONLOYALTY_ENROLLMENT_LINK_CLICKED]: IAccountSidebarBasePayload;
};
