export const UPDATE_USER_DATA = 'update.user.data';
export const CLEAR_USER_DATA = 'clear.user.data';
export const ACCOUNT_OVERLAY_SHOW = 'account.overlay.show';
export const ACCOUNT_OVERLAY_HIDE = 'account.overlay.hide';
export const ADD_USER_ADDRESS = 'account.add.user.address';

export interface IUpdateUserData {
    userId: string;
    userEmail: string;
    userRole: string;
    userCreationDate: string;
}

export interface IUserAddressAttributes {
    [field: string]: string;
}

export interface IUserAddress {
    addressIsDefault?: boolean;
    addressMetadataId: string;
    addressAttributes: IUserAddressAttributes;
}

export type IAddUserAddressPayload = IUserAddress;

export type ServiceUserProfileCommands = {
    [UPDATE_USER_DATA]: IUpdateUserData;
    [CLEAR_USER_DATA]: undefined;
    [ACCOUNT_OVERLAY_SHOW]: undefined;
    [ACCOUNT_OVERLAY_HIDE]: undefined;
    [ADD_USER_ADDRESS]: IAddUserAddressPayload;
};

export type ServiceUserProfileCommandsReturnType = {
    [UPDATE_USER_DATA]: undefined;
    [CLEAR_USER_DATA]: undefined;
    [ACCOUNT_OVERLAY_SHOW]: undefined;
    [ACCOUNT_OVERLAY_HIDE]: undefined;
    [ADD_USER_ADDRESS]: Promise<string>;
};
