export const VTO_ALL_SHADES_VIEWED = 'vto.allShadesViewed';
export const VTO_BEST_SHADES_VIEWED = 'vto.bestShadesViewed';
export const VTO_CAMERA_CLOSED = 'vto.cameraClosed';
export const VTO_CAMERA_FAILED = 'vto.cameraFailed';
export const VTO_CAMERA_OPENED = 'vto.cameraOpened';
export const VTO_CLOSED = 'vto.closed';
export const VTO_COMPARE = 'vto.compare';
export const VTO_COMPARE_DISABLED = 'vto.compareDisabled';
export const VTO_ENABLED = 'vto.enabled';
export const VTO_ENGINE_CLOSED = 'vto.engineClosed';
export const VTO_FOUNDATION_OVERLAY_TOGGLED = 'vto.foundation.overlay.toggled';
export const VTO_FOUNDATION_QR_CODE_OVERLAY_CLOSED = 'vto.foundationQrCodeOverlayClosed';
export const VTO_FOUNDATION_QR_CODE_OVERLAY_OPENED = 'vto.foundationQrCodeOverlayOpened';
export const VTO_KISS_DETECTED = 'vto.kissDetected';
export const VTO_LOADED = 'vto.loaded';
export const VTO_LOADING = 'vto.loading';
export const VTO_MATCH_REMOVED = 'vto.match.removed';
export const VTO_MATCH_SAVED = 'vto.match.saved';
export const VTO_MATCH_PERSONALIZED = 'vto.match.personalized';
export const VTO_OPENED = 'vto.opened';
export const VTO_PAIR_SHADE_GRID_OPENED = 'vto.pairShadeGridOpened';
export const VTO_PAIR_SHADE_SELECTED = 'vto.pairShadeSelected';
export const VTO_PHOTO_LOADED = 'vto.photoLoaded';
export const VTO_PHOTO_SAVED = 'vto.photoSaved';
export const VTO_PRICE_SUPPRESSED = 'vto.priceSuppressed';
export const VTO_RESET = 'vto.reset';
export const VTO_SHADE_FINDER_CANCELLED = 'vto.shadeFinderCancelled';
export const VTO_SHADE_FINDER_COMPLETED = 'vto.shadeFinderCompleted';
export const VTO_SHADE_FINDER_STARTED = 'vto.shadeFinderStarted';
export const VTO_SHADE_REMINDER_OVERLAY_CLOSED = 'vto.shadeReminderOverlayClosed';
export const VTO_SHADE_REMINDER_OVERLAY_OPENED = 'vto.shadeReminderOverlayOpened';
export const VTO_SHADE_REMINDER_OVERLAY_RETAINED = 'vto.shadeReminderOverlayRetained';
export const VTO_SHADE_REMINDER_OVERLAY_RETAKEN = 'vto.shadeReminderOverlayRetaken';
export const VTO_SHADE_SELECTED = 'vto.shadeSelected';
export const VTO_SKIN_ANALYSIS_FINISHED = 'vto.skin.care.analysisFinished';
export const VTO_SKIN_ANALYSIS_STARTED = 'vto.skin.care.analysisStarted';
export const VTO_SKIN_BACK_TO_DETECTING = 'vto.skin.care.backToDetecting';
export const VTO_SKIN_CARE_CAMERA_CLOSED = 'vto.skin.care.cameraClosed';
export const VTO_SKIN_CARE_CAMERA_FAILED = 'vto.skin.care.cameraFailed';
export const VTO_SKIN_CARE_CAMERA_OPENED = 'vto.skin.care.cameraOpened';
export const VTO_SKIN_CARE_CLOSED = 'vto.skin.care.closed';
export const VTO_SKIN_CARE_LOADED = 'vto.skin.care.loaded';
export const VTO_SKIN_CARE_LOADING = 'vto.skin.care.loading';
export const VTO_SKIN_CARE_OPENED = 'vto.skin.care.opened';
export const VTO_SKIN_CARE_PRODUCTS_LOADED = 'vto.skinCareProductsLoaded';
export const VTO_SKIN_CARE_RESULT_LOADED = 'vto.skin.care.result.loaded';
export const VTO_SKIN_CARE_VIDEO_LOADED = 'vto.skin.care.videoLoaded';
export const VTO_SKIN_SHADE_FOUND = 'vto.skinShadeFound';
export const VTO_SPLIT_FOUR_WAY_DISABLED = 'vto.splitFourWayDisabled';
export const VTO_SPLIT_FOUR_WAY_ENABLED = 'vto.splitFourWayEnabled';
export const VTO_SURVEY_IGNORED = 'vto.surveyIgnored';
export const VTO_SURVEY_LOADED = 'vto.surveyLoaded';
export const VTO_SURVEY_SUBMITTED = 'vto.surveySubmitted';
export const VTO_VIDEO_LOADED = 'vto.videoLoaded';
export const VTO_ZOOM = 'vto.zoom';
export const VTO_TUTORIAL_STARTED = 'vto.tutorialStarted';
export const VTO_TUTORIAL_UNMUTED = 'vto.tutorialUnmuted';
export const VTO_TUTORIAL_MUTED = 'vto.tutorialMuted';
export const VTO_TUTORIAL_PAUSED = 'vto.tutorialPaused';
export const VTO_TUTORIAL_STOPPED = 'vto.tutorialStopped';
export const VTO_TUTORIAL_RESUMED = 'vto.tutorialResumed';
export const VTO_TUTORIAL_PLAY_SPEED_UPDATED = 'vto.tutorialPlaySpeedUpdated';
export const VTO_TUTORIAL_PREVIOUS_STEP = 'vto.tutorialPreviousStep';
export const VTO_TUTORIAL_NEXT_STEP = 'vto.tutorialNextStep';
export const VTO_TUTORIAL_LOOK_SELECTED = 'vto.lookSelected';
export const VTO_TUTORIAL_PRODUCT_LIST_CLICKED = 'vto.productListClicked';
export const VTO_TUTORIAL_PRODUCTS_USED_ITEM_CLICKED = 'vto.productsUsedItemClicked';
export const VTO_BROW_FACE_ATTRIBUTE_PREDICTION_STARTED = 'vto.faceAttributePredictionStarted';
export const VTO_BROW_FACE_ATTRIBUTE_UPDATED = 'vto.faceAttributeUpdated';
export const VTO_BROW_GOAL_SELECTED = 'vto.browGoalSelected';
export const VTO_BROW_TIPS_CLICKED = 'vto.tipsClicked';
export const VTO_BROW_BACK_TO_GOALS_CLICKED = 'vto.backToGoalsClicked';
export const VTO_BROW_SHAPE_CHANGED = 'vto.shapeChanged';
export const VTO_BROW_SHADE_TRY_ON = 'vto.browShadeTryOn';
export const VTO_BROW_LENGTH_CHANGED = 'vto.lengthChanged';
export const VTO_BROW_THICKNESS_CHANGED = 'vto.thicknessChanged';
export const VTO_BROW_OPACITY_CHANGED = 'vto.opacityChanged';
export const VTO_BROW_GOLDEN_RATION_ENABLED = 'vto.goldenRatioEnabled';
export const VTO_BROW_GOLDEN_RATION_DISABLED = 'vto.goldenRatioDisabled';
export const VTO_BROW_STENCIL_ENABLED = 'vto.stencilEnabled';
export const VTO_BROW_STENCIL_DISABLED = 'vto.stencilDisabled';
export const VTO_BROW_COMPARE_ENABLED = 'vto.compareEnabled';
export const VTO_BROW_CAPTURE_CLICKED = 'vto.captureClicked';
export const VTO_BROW_SEE_ALL_SHADES_CLICKED = 'vto.seeAllShadesClicked';
export const VTO_BROW_GET_BROW_CLICKED = 'vto.getBrowClicked';

interface IVtoEvent {
    vtoType?: string;
    vtoMediaType?: string;
}
interface ISkinCareProductsLoaded extends IVtoEvent {
    isShoppable: boolean;
}
interface IFoundationOverlayOpened extends IVtoEvent {
    isOverlayOpened: boolean;
}
interface IVtoLoaded extends IVtoEvent {
    eventValue?: number;
}
interface IVtoClosed extends IVtoEvent {
    eventValue?: number;
}
interface IVtoShadeSelected extends IVtoEvent {
    eventLabel: string;
}

interface IVtoLookSelected extends IVtoEvent {
    vtoLookIndex?: number;
    vtoLookId?: string;
}

interface IVtoProductsUsedItemClicked extends IVtoEvent {
    vtoItemId?: string;
}

interface IVtoBrowGoalSelected extends IVtoEvent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vtoBrowGoals?: any[];
}

interface IVtoShapeChanged extends IVtoEvent {
    vtoBrowShape?: string;
}

interface IVtoBrowShadeTryOn extends IVtoEvent {
    vtoBrowSkuId?: string;
}

interface IVtoLengthChanged extends IVtoEvent {
    vtoBrowLength?: string;
}

interface IVtoThicknessChanged extends IVtoEvent {
    vtoBrowThickness?: string;
}

interface IVtoOpacityChanged extends IVtoEvent {
    vtoBrowOpacity?: number;
}

export type ServiceVtoEvents = {
    [VTO_ALL_SHADES_VIEWED]: IVtoEvent | void;
    [VTO_BEST_SHADES_VIEWED]: IVtoEvent | void;
    [VTO_CAMERA_CLOSED]: IVtoEvent | void;
    [VTO_CAMERA_FAILED]: IVtoEvent | void;
    [VTO_CAMERA_OPENED]: IVtoEvent | void;
    [VTO_CLOSED]: IVtoClosed | void;
    [VTO_COMPARE]: IVtoEvent | void;
    [VTO_COMPARE_DISABLED]: IVtoEvent | void;
    [VTO_ENABLED]: IVtoEvent;
    [VTO_ENGINE_CLOSED]: IVtoEvent | void;
    [VTO_FOUNDATION_OVERLAY_TOGGLED]: IFoundationOverlayOpened;
    [VTO_FOUNDATION_QR_CODE_OVERLAY_CLOSED]: IVtoEvent | void;
    [VTO_FOUNDATION_QR_CODE_OVERLAY_OPENED]: IVtoEvent | void;
    [VTO_KISS_DETECTED]: IVtoEvent | void;
    [VTO_LOADED]: IVtoLoaded | void;
    [VTO_LOADING]: IVtoEvent | void;
    [VTO_MATCH_REMOVED]: IVtoEvent | void;
    [VTO_MATCH_SAVED]: IVtoEvent | void;
    [VTO_MATCH_PERSONALIZED]: IVtoEvent | void;
    [VTO_OPENED]: IVtoEvent | void;
    [VTO_PAIR_SHADE_GRID_OPENED]: IVtoEvent | void;
    [VTO_PAIR_SHADE_SELECTED]: IVtoEvent | void;
    [VTO_PHOTO_LOADED]: IVtoEvent | void;
    [VTO_PHOTO_SAVED]: IVtoEvent | void;
    [VTO_PRICE_SUPPRESSED]: IVtoEvent | void;
    [VTO_RESET]: IVtoEvent | void;
    [VTO_SHADE_FINDER_CANCELLED]: IVtoEvent | void;
    [VTO_SHADE_FINDER_COMPLETED]: IVtoEvent | void;
    [VTO_SHADE_FINDER_STARTED]: IVtoEvent | void;
    [VTO_SHADE_REMINDER_OVERLAY_CLOSED]: IVtoEvent;
    [VTO_SHADE_REMINDER_OVERLAY_OPENED]: IVtoEvent;
    [VTO_SHADE_REMINDER_OVERLAY_RETAINED]: IVtoEvent;
    [VTO_SHADE_REMINDER_OVERLAY_RETAKEN]: IVtoEvent;
    [VTO_SHADE_SELECTED]: IVtoShadeSelected;
    [VTO_SKIN_ANALYSIS_FINISHED]: IVtoEvent | void;
    [VTO_SKIN_ANALYSIS_STARTED]: IVtoEvent | void;
    [VTO_SKIN_BACK_TO_DETECTING]: IVtoEvent | void;
    [VTO_SKIN_CARE_CAMERA_CLOSED]: IVtoEvent | void;
    [VTO_SKIN_CARE_CAMERA_FAILED]: IVtoEvent | void;
    [VTO_SKIN_CARE_CAMERA_OPENED]: IVtoEvent | void;
    [VTO_SKIN_CARE_CLOSED]: IVtoEvent | void;
    [VTO_SKIN_CARE_CLOSED]: IVtoEvent | void;
    [VTO_SKIN_CARE_LOADED]: IVtoEvent | void;
    [VTO_SKIN_CARE_LOADING]: IVtoEvent | void;
    [VTO_SKIN_CARE_OPENED]: IVtoEvent | void;
    [VTO_SKIN_CARE_PRODUCTS_LOADED]: ISkinCareProductsLoaded;
    [VTO_SKIN_CARE_RESULT_LOADED]: IVtoEvent | void;
    [VTO_SKIN_CARE_VIDEO_LOADED]: IVtoEvent | void;
    [VTO_SKIN_SHADE_FOUND]: IVtoEvent | void;
    [VTO_SPLIT_FOUR_WAY_DISABLED]: IVtoEvent;
    [VTO_SPLIT_FOUR_WAY_ENABLED]: IVtoEvent;
    [VTO_SURVEY_IGNORED]: IVtoEvent;
    [VTO_SURVEY_LOADED]: IVtoEvent;
    [VTO_SURVEY_SUBMITTED]: IVtoEvent;
    [VTO_VIDEO_LOADED]: IVtoEvent | void;
    [VTO_ZOOM]: IVtoEvent | void;
    [VTO_TUTORIAL_STARTED]: IVtoEvent | void;
    [VTO_TUTORIAL_UNMUTED]: IVtoEvent | void;
    [VTO_TUTORIAL_MUTED]: IVtoEvent | void;
    [VTO_TUTORIAL_PAUSED]: IVtoEvent | void;
    [VTO_TUTORIAL_STOPPED]: IVtoEvent | void;
    [VTO_TUTORIAL_RESUMED]: IVtoEvent | void;
    [VTO_TUTORIAL_PLAY_SPEED_UPDATED]: IVtoEvent | void;
    [VTO_TUTORIAL_PREVIOUS_STEP]: IVtoEvent | void;
    [VTO_TUTORIAL_NEXT_STEP]: IVtoEvent | void;
    [VTO_TUTORIAL_LOOK_SELECTED]: IVtoLookSelected;
    [VTO_TUTORIAL_PRODUCT_LIST_CLICKED]: IVtoEvent | void;
    [VTO_TUTORIAL_PRODUCTS_USED_ITEM_CLICKED]: IVtoProductsUsedItemClicked;
    [VTO_BROW_FACE_ATTRIBUTE_PREDICTION_STARTED]: IVtoEvent | void;
    [VTO_BROW_FACE_ATTRIBUTE_UPDATED]: IVtoEvent | void;
    [VTO_BROW_GOAL_SELECTED]: IVtoBrowGoalSelected;
    [VTO_BROW_TIPS_CLICKED]: IVtoEvent | void;
    [VTO_BROW_BACK_TO_GOALS_CLICKED]: IVtoEvent | void;
    [VTO_BROW_SHAPE_CHANGED]: IVtoShapeChanged;
    [VTO_BROW_SHADE_TRY_ON]: IVtoBrowShadeTryOn;
    [VTO_BROW_LENGTH_CHANGED]: IVtoLengthChanged;
    [VTO_BROW_THICKNESS_CHANGED]: IVtoThicknessChanged;
    [VTO_BROW_OPACITY_CHANGED]: IVtoOpacityChanged;
    [VTO_BROW_GOLDEN_RATION_ENABLED]: IVtoEvent | void;
    [VTO_BROW_GOLDEN_RATION_DISABLED]: IVtoEvent | void;
    [VTO_BROW_STENCIL_ENABLED]: IVtoEvent | void;
    [VTO_BROW_STENCIL_DISABLED]: IVtoEvent | void;
    [VTO_BROW_COMPARE_ENABLED]: IVtoEvent | void;
    [VTO_BROW_CAPTURE_CLICKED]: IVtoEvent | void;
    [VTO_BROW_SEE_ALL_SHADES_CLICKED]: IVtoEvent | void;
    [VTO_BROW_GET_BROW_CLICKED]: IVtoEvent | void;
};
