import { ServiceBus, OptionsType } from './ServiceBus';
/*
 * Decorator attached on object properties that watches for the result
 * of a certain query to change.
 */
type PrimitiveTypes = boolean | string | number | null | undefined;
export const getDecorators = <E, Q, QR, C, CR>(globalServiceBus: ServiceBus<E, Q, QR, C, CR>) => {
    const watchQuery = <K extends keyof Q>(name?: K) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any,
        key: string
    ) => {
        const queryName = name || key;
        target.__serviceBusDecorators__ = target.__serviceBusDecorators__ || {};
        const hasWatchingKey = target.__serviceBusDecorators__[queryName];
        if (!hasWatchingKey) {
            target.__serviceBusDecorators__[queryName] = key;
        }

        target.registerWatchers = function () {
            Object.keys(this.__serviceBusDecorators__).map((sname) => {
                globalServiceBus.registerQueryWatcher(sname, (newQueryResult: PrimitiveTypes) => {
                    const propertyName = this.__serviceBusDecorators__[sname];
                    this[propertyName] = newQueryResult;
                });
            });
        };
    };

    /**
     * Method decorator used to mark a method as a query provider
     */
    const queryProvider = <K extends keyof Q & keyof QR>(queryName: K) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any,
        key: string,
        descriptor?: TypedPropertyDescriptor<() => PrimitiveTypes>
    ) => {
        const queryToProvide = queryName;
        if (!descriptor) {
            Object.defineProperty(target, key, {
                configurable: true,
                enumerable: false,
                get() {
                    return undefined;
                },
                set(value) {
                    globalServiceBus.registerQueryProvider(
                        queryToProvide,
                        (payload: Q[K]): QR[K] => {
                            return value.call(target, payload);
                        }
                    );
                }
            });
        }
    };

    /**
     * Method decorator used to mark a method as a command provider
     */
    const commandProvider = <K extends keyof C & keyof CR>(queryName: K) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any,
        key: string,
        descriptor?: TypedPropertyDescriptor<() => CR[K]>
    ) => {
        const queryToProvide = queryName;
        if (!descriptor) {
            Object.defineProperty(target, key, {
                configurable: true,
                enumerable: false,
                get() {
                    return undefined;
                },
                set(value) {
                    globalServiceBus.registerCommandProvider(
                        queryToProvide,
                        (payload: [C[K]]): CR[K] => {
                            return value.call(target, payload);
                        }
                    );
                }
            });
        }
    };

    /*
     * Decorator attached on object properties that listens for a specified event
     * to happen
     */
    function on<K extends keyof E>(evName: K, options?: OptionsType) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (target: any, key: string, descriptor?: PropertyDescriptor) => {
            if (!descriptor) {
                Object.defineProperty(target, key, {
                    configurable: true,
                    enumerable: false,
                    get() {
                        return undefined;
                    },
                    set(value) {
                        globalServiceBus.on(
                            evName,
                            (payload: E[K]) => {
                                value.call(target, payload);
                            },
                            options
                        );
                    }
                });
            } else {
                globalServiceBus.on(
                    evName,
                    (payload: E[K]) => {
                        descriptor.value.call(target, payload);
                    },
                    options
                );
            }
        };
    }

    /**
     * Method use to emit events on the global service bus
     */
    function emit<K extends keyof E>(
        evt: K,
        ...payload: E[K] extends undefined ? [undefined?] : [E[K]]
    ) {
        globalServiceBus.emit(evt, payload[0]);
    }

    /**
     * Method use to query for data in an imperative manner
     */
    function query<K extends keyof Q & keyof QR>(
        name: K,
        ...payload: Q[K] extends undefined ? [undefined?] : [Q[K]]
    ): QR[K] {
        return globalServiceBus.query(name, payload[0]);
    }
    /**
     * Method use to command for data in an imperative manner
     */
    function command<K extends keyof C & keyof CR>(
        name: K,
        ...payload: C[K] extends undefined ? [undefined?] : [C[K]]
    ): CR[K] {
        return globalServiceBus.command(name, payload[0]);
    }

    return {
        command,
        commandProvider,
        query,
        watchQuery,
        queryProvider,
        emit,
        on
    };
};
