import { ICart, IOfferMessage } from './CartInterfaces';

export const CART_BADGE_CLICKED = 'cart.badge.clicked';
export const MOBILE_CART_BADGE_CLICKED = 'mobile.cart.badge.clicked';
export const CART_SAMPLES_SET = 'cart.samples.set';
export const CART_UPDATED = 'cart.updated';
export const CART_VIEWED = 'cart.viewed';
export const CART_FAILURE = 'cart.failure';
export const SHIPPING_OPTION_SELECTED = 'shipping.option.selected';
export const EDIT_ADDRESS_CLICKED = 'edit.address.clicked';
export const CONTINUE_AS_GUEST_CLICKED = 'continue.as.guest.clicked';
export const GUEST_DESELECTED = 'guest.deselected';
export const CART_PAGE_VIEW = 'cart.page.view';
export const CART_PRODUCT_SKU_LIMIT_REACHED = 'cart.product.sku.limit.reached';
export const SHOPPING_BAG_BUTTON_CLICKED = 'shoppingbag.button.clicked';
export const MANUAL_OFFER_CODE_APPLIED = 'checkout.manualOfferCodeApplied';
export const REMOVE_ITEM = 'checkout.removeItem';
export const ITEM_ADDED = 'item.added';
export const REVIEW_SCREEN_SUBMITTING = 'review.screen.submitting';
export const SAVE_FOR_LATER_REMOVE = 'saveforlater.removeItem';
export const CART_LOGGED_IN = 'cart.logged.in';
export const SAMPLE_ADDED = 'sample.added';
export const CHANGE_LOCATION = 'change.location';
export const OFFER_CODE_FAILED = 'offer.code.failed';
export const NOT_ENOUGH_PRODUCTS = 'not.enough.products';
export const CART_PAYMENT_SOURCES_SET = 'cart.payment.methods.set';

export interface IContinueAsGuestEvent {
    source: string;
    email: string;
}

export interface IGuestDeselectedEvent {
    guestEmail: string;
}

export interface ILimitReachedEventData {
    itemMaxQuantity: number;
}

export interface IRemoveItemEvent {
    skuId: string;
}

export interface IAddItemEvent {
    skuId: string;
    quantity: number;
}

export interface IRemoveSaveForLaterEvent {
    skuId: string;
    quantity: number;
}

export interface IChangeLocation {
    redirectUrl: string;
}

export interface ISamplesSetEvent {
    skuIds: string[];
    offerCode: string;
    offerType: string;
}

export interface IPaymentMethodDescription {
    providerId: string;
    methodId: string;
}

export interface ICartPaymentMethodsSetEventData {
    paymentSources: IPaymentMethodDescription[];
}

export type ServiceCartEvents = {
    [CART_BADGE_CLICKED]: undefined;
    [MOBILE_CART_BADGE_CLICKED]: undefined;
    [CART_UPDATED]: ICart;
    [CART_VIEWED]: undefined;
    [CART_FAILURE]: ICart;
    [SHIPPING_OPTION_SELECTED]: undefined;
    [EDIT_ADDRESS_CLICKED]: undefined;
    [CONTINUE_AS_GUEST_CLICKED]: IContinueAsGuestEvent;
    [GUEST_DESELECTED]: IGuestDeselectedEvent;
    [CART_PAGE_VIEW]: undefined;
    [CART_PRODUCT_SKU_LIMIT_REACHED]: ILimitReachedEventData;
    [SHOPPING_BAG_BUTTON_CLICKED]: undefined;
    [MANUAL_OFFER_CODE_APPLIED]: undefined;
    [REMOVE_ITEM]: IRemoveItemEvent;
    [ITEM_ADDED]: IAddItemEvent;
    [REVIEW_SCREEN_SUBMITTING]: undefined;
    [SAVE_FOR_LATER_REMOVE]: IRemoveSaveForLaterEvent;
    [CART_LOGGED_IN]: undefined;
    [SAMPLE_ADDED]: undefined;
    [CHANGE_LOCATION]: IChangeLocation;
    // an undefined payload can occur on any network error or 500 codes
    [OFFER_CODE_FAILED]: IOfferMessage | undefined;
    [NOT_ENOUGH_PRODUCTS]: undefined;
    [CART_SAMPLES_SET]: ISamplesSetEvent;
    [CART_PAYMENT_SOURCES_SET]: ICartPaymentMethodsSetEventData;
};
