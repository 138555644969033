export const GET_MANUAL_OFFER = 'get.manual.offer';
export const GET_MANUAL_OFFER_BY_CODE = 'get.manual.offer.code';

export interface IMessage {
    readonly threshold: string | null;
    readonly deferred: string | null;
    readonly success: string | null;
    readonly error: string | null;
}
export interface IManualOffer {
    manualOfferCodeError: string;
    manualOfferMessages: IMessage;
    manualOfferCode: string;
    shouldDisplayMessage: boolean;
    hasOfferCodeError: boolean;
    offerCode: string;
    message: IMessage;
    offerType: string;
}
export type ServiceOffersQueries = {
    [GET_MANUAL_OFFER]: undefined;
    [GET_MANUAL_OFFER_BY_CODE]: string[];
};

export type ServiceOffersQueriesResponseType = {
    [GET_MANUAL_OFFER]: IManualOffer;
    [GET_MANUAL_OFFER_BY_CODE]: IManualOffer;
};
