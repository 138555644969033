export const PAGE_VIEW_TRACK_PRODUCT_GRID = 'page.view.track.product-grid';
export const PAGE_VIEW_TRACK_PRODUCT_FULL = 'page.view.track.product-full';

export type ServiceAnalyticsQueries = {
    [PAGE_VIEW_TRACK_PRODUCT_GRID]: {};
    [PAGE_VIEW_TRACK_PRODUCT_FULL]: {};
};

export type ServiceAnalyticsQueriesResponseType = {
    [PAGE_VIEW_TRACK_PRODUCT_GRID]: {};
    [PAGE_VIEW_TRACK_PRODUCT_FULL]: {};
};
