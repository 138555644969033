export const BOOK_APPOINTMENT_CLICKED = 'book.appointment.clicked';
export const GET_DIRECTIONS_CLICKED = 'get.directions.clicked';
export const SEARCH_ADDRESS_SUBMITTED = 'search.address.submited';
export const STORE_DETAILS_CLICKED = 'store.detail.clicked';
export const STORE_INFO_CLICKED = 'store.info.clicked';
export const STORE_MARKER_CLICKED = 'store.marker.clicked';
export const STORE_PHONE_CLICKED = 'store.phone.clicked';
export const BOOK_VIRTUAL_APPOINTMENT_CLICKED = 'book.virtual.appointment.clicked';
export const STORE_DELIVERY_TYPE_SELECTED = 'store.delivery.type.selected';
export const STORE_SEARCH_ADDRESS_SUBMITTED = 'store.search.address.submited';
export const STORE_CHANGE_LINK_CLICKED = 'store.change.link.clicked';
export const STORE_SELECTED = 'store.selected';
export const STORE_OPENING_HOURS_TOGGLED = 'store.opening.hours.toggled';
export const STORE_FILTER_TOGGLED = 'store.filter.toggled';
export const STORE_DIRECTORY_LINK_CLICKED = 'store.directory.link.clicked';
export const STORE_DIRECTORY_MAP_LINK_CLICKED = 'store.directory.map.link.clicked';
export const STORE_DIRECTORY_STORE_LINK_CLICKED = 'store.directory.store.link.clicked';
export const STORE_MAP_MARKER_CLICKED = 'store.map.marker.clicked';
export const STORE_GET_DIRECTIONS_CLICKED = 'store.get.directions.clicked';
export const STORE_DETAILS_LINK_CLICKED = 'store.details.link.clicked';
export const STORE_BOOK_APPOINTMENT_LINK_CLICKED = 'store.book.appointment.link.clicked';
export const STORE_DETAILS_ADDRESS_LINK_CLICKED = 'store.details.address.link.clicked';
export const STORE_DETAILS_IN_STORE_PICKUP_LINK_CLICKED = 'store.details.in.store.pickup.link.clicked';
export const STORE_NEAR_BY_GET_DIRECTIONS_CLICKED = 'store.near.by.get.directions.link.clicked';
export const STORE_LOCATION_RESET_CLICKED = 'store.location.reset.link.clicked';
export const STORE_TAB_VIEW_SECTION_CLICKED = 'store.tab.view.section.clicked';

export interface IStoreData {
    storeId?: string;
    storeName?: string | undefined;
}

export interface ISearchAddress {
    address?: string | undefined;
}

export interface IVirtualAppointmentData {
    isVirtual: boolean;
}

export interface IStoreDeliveryMethodData {
    eventAction: string;
    eventLabel: string;
    eventName?: string;
    eventCategory?: string;
}

export type ServiceStoresEvents = {
    [BOOK_APPOINTMENT_CLICKED]: IStoreData | void;
    [GET_DIRECTIONS_CLICKED]: IStoreData | void;
    [SEARCH_ADDRESS_SUBMITTED]: ISearchAddress;
    [STORE_DETAILS_CLICKED]: IStoreData | void;
    [STORE_INFO_CLICKED]: IStoreData | void;
    [STORE_MARKER_CLICKED]: IStoreData | void;
    [STORE_PHONE_CLICKED]: IStoreData | void;
    [BOOK_VIRTUAL_APPOINTMENT_CLICKED]: IVirtualAppointmentData | void;
    [STORE_DELIVERY_TYPE_SELECTED]: IStoreDeliveryMethodData | void;
    [STORE_SEARCH_ADDRESS_SUBMITTED]: IStoreDeliveryMethodData | void;
    [STORE_CHANGE_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_SELECTED]: IStoreDeliveryMethodData | void;
    [STORE_OPENING_HOURS_TOGGLED]: IStoreDeliveryMethodData | void;
    [STORE_FILTER_TOGGLED]: IStoreDeliveryMethodData | void;
    [STORE_DIRECTORY_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_DIRECTORY_MAP_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_DIRECTORY_STORE_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_MAP_MARKER_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_GET_DIRECTIONS_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_DETAILS_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_BOOK_APPOINTMENT_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_DETAILS_ADDRESS_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_DETAILS_IN_STORE_PICKUP_LINK_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_NEAR_BY_GET_DIRECTIONS_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_LOCATION_RESET_CLICKED]: IStoreDeliveryMethodData | void;
    [STORE_TAB_VIEW_SECTION_CLICKED]: IStoreDeliveryMethodData | void;
};
