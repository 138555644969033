export const IAM_SIGN_UP_SUCCESSFUL = 'iam.sign.up.successful';
export const IAM_SIGN_UP_FAILED = 'iam.sign.up.failed';
export const IAM_SIGN_IN_SUCCESSFUL = 'iam.sign.in.successful';
export const IAM_SIGN_IN_FAILED = 'iam.sign.in.failed';

interface IIamEventData {
    source: string;
    provider: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIamSignInEventData extends IIamEventData {}

export interface IIamSignUpEventData extends IIamEventData {
    type: string;
}

export type ServiceIamEvents = {
    [IAM_SIGN_UP_SUCCESSFUL]: IIamSignUpEventData;
    [IAM_SIGN_UP_FAILED]: IIamSignUpEventData;
    [IAM_SIGN_IN_SUCCESSFUL]: IIamSignInEventData;
    [IAM_SIGN_IN_FAILED]: IIamSignInEventData;
};
