export const AUTH_UPDATE_DATA = 'auth.update.data';
export const SET_SIGN_IN_EMAIL = 'set.sign.in.email';

export interface IAuthUpdateData {
    token: string;
    source?: string;
    email?: string;
    expiration: number;
}

export type ServiceSignInCommands = {
    [AUTH_UPDATE_DATA]: IAuthUpdateData;
    [SET_SIGN_IN_EMAIL]: { email: string };
};

export type ServiceSignInCommandsReturnType = {
    [AUTH_UPDATE_DATA]: undefined;
    [SET_SIGN_IN_EMAIL]: undefined;
};
