export const RCO_PAYMENT_SELECTED = 'rco.payment.selected';
export const RCO_SHIPPING_ADDRESS_ADD = 'rco.shipping.address.add';
export const RCO_SHIPPING_OPTION_SELECTED = 'rco.shipping.option.selected';
export const RCO_EMAIL_SIGNUP = 'rco.account.email.signup';
export const RCO_LINK_CLICKED = 'rco.link.clicked';
export const RCO_SAME_DAY_DELIVERY_TIME_SELECTED = 'rco.same.day.delivery.selected';
export const RCO_PAYMENT_DELIVERY_EDITED = 'rco.payment.delivery.edited';
export const RCO_DELIVERY_OPTION_SELECTED = 'rco.delivery.option.selected';

export const RCO_BOPIS_STORE_SEARCHED = 'rco.bopis.store.searched';
export const RCO_BOPIS_STORE_SELECTED = 'rco.bopis.store.selected';
export const RCO_BOPIS_STORE_HOURS_OPENED = 'rco.bopis.store.hours.opened';
export const RCO_BOPIS_STORE_CHANGED = 'rco.bopis.store.changed';
export const RCO_BOPIS_CART_EDITED = 'rco.bopis.cart.edited';
export const RCO_BOPIS_DELIVERY_TIME_EDITED = 'rco.bopis.delivery.time.edited';
export const RCO_BOPIS_UNAVAILABLE_ITEMS_REMOVED = 'rco.bopis.unavailable.items.removed';

interface IPaymentSelected {
    paymentType: string;
}

interface IShippingOptionSelected {
    option: string;
}

interface ILinkClicked {
    option: string;
    pageName: string;
}

interface IStoreName {
    storeName: string;
}
interface IProductIds {
    productIds: string[];
}

export type ReactCheckoutAppEvents = {
    [RCO_PAYMENT_SELECTED]: IPaymentSelected;
    [RCO_SHIPPING_ADDRESS_ADD]: undefined;
    [RCO_SHIPPING_OPTION_SELECTED]: IShippingOptionSelected;
    [RCO_EMAIL_SIGNUP]: undefined;
    [RCO_LINK_CLICKED]: ILinkClicked;
    [RCO_SAME_DAY_DELIVERY_TIME_SELECTED]: undefined;
    [RCO_PAYMENT_DELIVERY_EDITED]: IShippingOptionSelected;
    [RCO_DELIVERY_OPTION_SELECTED]: IShippingOptionSelected;
    [RCO_BOPIS_STORE_SEARCHED]: ILinkClicked;
    [RCO_BOPIS_STORE_SELECTED]: IStoreName;
    [RCO_BOPIS_STORE_HOURS_OPENED]: IStoreName;
    [RCO_BOPIS_STORE_CHANGED]: IStoreName;
    [RCO_BOPIS_CART_EDITED]: IProductIds;
    [RCO_BOPIS_DELIVERY_TIME_EDITED]: undefined;
    [RCO_BOPIS_UNAVAILABLE_ITEMS_REMOVED]: IProductIds;
};
