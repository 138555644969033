import { IOfferMessage } from './CartInterfaces';

export const OFFERS_LOADED = 'offers.loaded';
export const APPLY_OFFER_CODE = 'apply.offer.code';
export const OFFER_CODE_SUCCEDED = 'offer.code.succeded';

export interface IMessage {
    readonly threshold: string | null;
    readonly deferred: string | null;
    readonly success: string | null;
    readonly error: string | null;
}

export type ServiceOffersEvents = {
    [OFFERS_LOADED]: IOfferMessage[];
    [APPLY_OFFER_CODE]: string;
    [OFFER_CODE_SUCCEDED]: {};
};
