export const ADD_SKU_TO_COLLECTION_TRIGGERED = 'sku.addToCollection';
export const ADD_SKU_TO_COLLECTION_SUCCESS = 'sku.addToCollectionSuccess';
export const ITEMS_LOADED_INTO_COLLECTION = 'sku.itemsLoadedIntoCollection';
export const GRID_FILTERED = 'grid.filtered';
export const GRID_SORTED = 'grid.sorted';
export const LIVE_CHAT_INTERFACE_CLICKED = 'product.liveChatInterfaceClicked';
export const MPP_FILTERED = 'mpp.filtered';
export const MPP_PAGE_VIEWED = 'mpp.page.viewed';
export const PRODUCTS_FILTERED_AND_SORTED = 'product.filteredAndSorted';
export const PRODUCT_CLICKED = 'product.clicked';
export const PRODUCT_COMPARISON_VIEWED = 'product.comparison.viewed';
export const PRODUCT_COMPARISON_ITEM_CLICKED = 'product.comparison.itemClicked';
export const ADD_TO_BAG_CLICKED = 'product.addToBagClicked';
export const PRODUCT_FULL_LOADED = 'product.full.loaded';
export const PRODUCT_FULL_VIEWED = 'product.full.viewed';
export const PRODUCT_GRID_VIEWED = 'product.grid.viewed';
export const PRODUCT_IMAGE_ENABLED = 'product.imageEnabled';
export const PRODUCT_IMAGE_DISPLAY_TRIGGERED = 'product.imageDisplay.triggered';
export const PRODUCT_IMAGE_NAVIGATION_TRIGGERED = 'product.imageNavigation.triggered';
export const PRODUCT_IMAGE_ZOOM_TRIGGERED = 'product.imageZoom.triggered';
export const PRODUCT_INFO_POPUP_CTA_CLICKED = 'product.infoPopupCtaClicked';
export const PRODUCT_LOADED = 'product.loaded';
export const PRODUCT_SHADE_GROUP_SELECTED = 'product.shadeGroupSelected';
export const PRODUCT_SHADE_SELECTED = 'product.shadeSelected';
export const PRODUCT_SHADE_SIZE_SELECTED = 'product.shadeSizeSelected';
export const PRODUCT_SKU_SELECTED = 'product.sku.selected';
export const PRODUCT_VIEWED = 'product.viewed';
export const QUICK_VIEW_TRIGGERED = 'quick.view.triggered';
export const SHADE_SIDEBAR_CLOSED = 'shade.sidebar.closed';
export const SHADE_SIDEBAR_IMAGE_ENABLED = 'shade.sidebar.imageEnabled';
export const SHADE_SIDEBAR_OPENED = 'shade.sidebar.opened';
export const SKU_ENGRAVING_TRIGGERED = 'sku.engraving.triggered';
export const SKU_PICKER_OPENED = 'sku.picker.opened';
export const SKU_PICKER_CLOSED = 'sku.picker.closed';
export const SOCIAL_SHARE_ICON_TRIGGERED = 'product.socialShareIconTriggered';
export const SPP_PAGE_LOADED = 'spp.page.loaded';
export const SPP_PAGE_VIEWED = 'spp.page.viewed';
export const SPP_POWER_REVIEWS_INIT = 'spp.powerReviews.init';
export const SPP_SELECTION = 'spp.shade.size.selected';

enum FILTER_METHODS {
    filter = 'filter',
    sort = 'sort'
}

export interface IAppliedFilter {
    category: string;
    option: string;
}

export interface IMPPPageViewedEvent {
    skuIds: string[];
}

interface IProductFullLoaded {
    productId: string;
    skuId: string;
}

interface IProductFullViewed {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    product: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedSku: any;
}

interface IProductGridViewed {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    products: any[];
}

interface IProductClicked {
    skuId: string;
    positionIndex: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    product?: any;
}

export interface IMppFiltered {
    method: FILTER_METHODS;
}
export interface IGridFitlered {
    appliedFilters: IAppliedFilter[];
}

export interface IGridSorted {
    appliedSort: string;
}

export interface IProductInfoPopupPayload {
    dataField: string;
    level?: string;
    header?: string;
}

export interface IProductImage {
    eventAction: string;
    eventLabel: string;
    enhancedAction?: string;
    promoCreative?: string;
    promoId?: string;
    promoName?: string;
    promoPosition?: number;
}

export interface ISkuEngravingEvent {
    skuId: string;
    skuBaseId?: number;
    size: string;
    price: string;
    name: string;
}

export interface IItemsCollectionInfo {
    productQtys: { [skuId in string | number]: number };
}

export type ServiceProdcatEvents = {
    [ADD_SKU_TO_COLLECTION_TRIGGERED]: { skuId: string; quantity?: number };
    [ADD_SKU_TO_COLLECTION_SUCCESS]: undefined;
    [ITEMS_LOADED_INTO_COLLECTION]: IItemsCollectionInfo;
    [GRID_FILTERED]: IGridFitlered;
    [GRID_SORTED]: IGridSorted;
    [LIVE_CHAT_INTERFACE_CLICKED]: { eventLabel?: string };
    [MPP_FILTERED]: IMppFiltered;
    [MPP_PAGE_VIEWED]: IMPPPageViewedEvent;
    [PRODUCTS_FILTERED_AND_SORTED]: {};
    [PRODUCT_CLICKED]: IProductClicked;
    [PRODUCT_COMPARISON_VIEWED]: { productIds: string[] };
    [PRODUCT_COMPARISON_ITEM_CLICKED]: { productPosition: number; productId: string };
    [ADD_TO_BAG_CLICKED]: { productId: string; context: string };
    [PRODUCT_FULL_LOADED]: IProductFullLoaded;
    [PRODUCT_FULL_VIEWED]: IProductFullViewed;
    [PRODUCT_GRID_VIEWED]: IProductGridViewed;
    [PRODUCT_IMAGE_ENABLED]: boolean;
    [PRODUCT_IMAGE_DISPLAY_TRIGGERED]: IProductImage;
    [PRODUCT_IMAGE_NAVIGATION_TRIGGERED]: IProductImage;
    [PRODUCT_IMAGE_ZOOM_TRIGGERED]: IProductImage;
    [PRODUCT_INFO_POPUP_CTA_CLICKED]: IProductInfoPopupPayload;
    [PRODUCT_LOADED]: string[];
    [PRODUCT_SHADE_GROUP_SELECTED]: {
        eventAction: string;
        eventName: string;
        shadeGroupKey: string;
    };
    [PRODUCT_SHADE_SELECTED]: { shadeName: string };
    [PRODUCT_SHADE_SIZE_SELECTED]: {};
    [PRODUCT_SKU_SELECTED]: { skuId: string; payload?: object };
    [PRODUCT_VIEWED]: { skuId: string };
    [QUICK_VIEW_TRIGGERED]: IProductClicked;
    [SHADE_SIDEBAR_CLOSED]: undefined;
    [SHADE_SIDEBAR_IMAGE_ENABLED]: { isEnabled: boolean };
    [SHADE_SIDEBAR_OPENED]: undefined;
    [SKU_ENGRAVING_TRIGGERED]: ISkuEngravingEvent;
    [SKU_PICKER_OPENED]: { productId: string };
    [SKU_PICKER_CLOSED]: { productId: string };
    [SOCIAL_SHARE_ICON_TRIGGERED]: { eventLabel?: string };
    [SPP_PAGE_LOADED]: {};
    [SPP_PAGE_VIEWED]: string;
    [SPP_POWER_REVIEWS_INIT]: string;
    [SPP_SELECTION]: {};
};
