export const GET_BEST_MATCH_SKU_ID = 'get.bestMatchSkuId';
export const GET_USER_DETAILS = 'get.userDetails';

export type ServiceVtoQueries = {
    [GET_BEST_MATCH_SKU_ID]: { productId: string };
    [GET_USER_DETAILS]: undefined;
};

export type ServiceVtoQueriesResponseType = {
    [GET_BEST_MATCH_SKU_ID]: string;
    [GET_USER_DETAILS]: Record<string, unknown>;
};
