export const ORDER_CREATED = 'order.created';
export const ORDER_CONFLICT = 'order.conflict';
export const ORDER_FAILED = 'order.failed';
export const ORDER_CONFIRMATION_VIEW = 'order.confirmation.page.landed';
export const ROUTE_NOT_FOUND = 'route.not.found';
export const ORDER_CHECK = 'order.check';
export const ORDER_ASSOCIATE = 'order.associate';
export const ORDER_ASSOCIATE_SUCCESS = 'order.associate.success';
export const ORDER_ASSOCIATE_FAILURE = 'order.associate.failure';

export interface IOrderAddress {
    addressMetadataId: string;
    attributes: {
        [key: string]: string;
    };
}

export interface IOrderCreated {
    id: string;
    amount: number;
    token: string;

    billingAddress: IOrderAddress;
    shippingAddresses: IOrderAddress[];
}

export type orderErrorCodeType = '402' | '409' | '';

export interface IOrderConflict {
    message: orderErrorCodeType;
}

export interface IOrderCheckEventData {
    orderId: string;
}

export interface IOrderDetail {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface IOrderAssociateEventData {
    orderId: string;
    guestToken: string;
}

export type ServiceOrderEvents = {
    [ORDER_CREATED]: IOrderCreated;
    [ORDER_CONFLICT]: IOrderConflict;
    [ORDER_FAILED]: IOrderDetail;
    [ORDER_CONFIRMATION_VIEW]: IOrderDetail;
    [ROUTE_NOT_FOUND]: undefined;
    [ORDER_CHECK]: IOrderCheckEventData;
    [ORDER_ASSOCIATE]: IOrderAssociateEventData;
    [ORDER_ASSOCIATE_SUCCESS]: IOrderAssociateEventData;
    [ORDER_ASSOCIATE_FAILURE]: IOrderAssociateEventData;
};
