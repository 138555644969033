export const READ_REVIEWS_LINK_CLICKED = 'read.reviews.link.clicked';
export const WRITE_REVIEWS_LINK_CLICKED = 'write.reviews.link.clicked';
export const REVIEWS_HELPFUL_YES_CLICKED = 'reviews.helpful.yes.clicked';
export const REVIEWS_HELPFUL_NO_CLICKED = 'reviews.helpful.no.clicked';
export const REVIEWS_FLAG_REVIEW_CLICKED = 'reviews.flag.review.clicked';
export const REVIEWS_SORT_BY_CHANGED = 'reviews.sort.by.changed';
export const REVIEWS_SEARCH_CLICKED = 'reviews.search.clicked';
export const REVIEWS_FILTER_CLICKED = 'reviews.filter.clicked';
export const ASK_AND_ANSWER_CLICKED = 'ask.and.answer.clicked';
export const ASK_AND_ANSWER_SUBMITTED = 'ask.and.answer.submitted';
export const ASK_AND_ANSWER_SEARCH_CLICKED = 'ask.and.answer.search.clicked';
export const ASK_AND_ANSWER_HELPFUL_YES_CLICKED = 'ask.and.answer.helpful.yes.clicked';
export const ASK_AND_ANSWER_HELPFUL_NO_CLICKED = 'ask.and.answer.helpful.no.clicked';
export const ASK_AND_ANSWER_SHOW_MORE_CLICKED = 'ask.and.answer.show.more.clicked';
export const ASK_AND_ANSWERS_SORT_BY_CHANGED = 'ask.and.answer.sort.by.changed';
export const WRITE_REVIEW_STARTED = 'write.review.started';
export const REVIEW_OVERLAY_OPENED = 'review.overlay.opened';
export const REVIEW_OVERLAY_CLOSED = 'review.overlay.closed';
export const REVIEWS_RATING_CLICKED = 'reviews.rating.clicked';
export const REVIEWS_PAGINATION_CLICKED = 'reviews.pagination.clicked';

export interface IReviewsPayload {
    id: string;
    name: string;
    reviewCategory: string;
    selectedValue?: string;
    reviewLink?: string;
}

export type ServiceRatingsReviewsEvents = {
    [READ_REVIEWS_LINK_CLICKED]: IReviewsPayload;
    [WRITE_REVIEWS_LINK_CLICKED]: IReviewsPayload;
    [REVIEWS_HELPFUL_YES_CLICKED]: IReviewsPayload;
    [REVIEWS_HELPFUL_NO_CLICKED]: IReviewsPayload;
    [REVIEWS_FLAG_REVIEW_CLICKED]: IReviewsPayload;
    [REVIEWS_SORT_BY_CHANGED]: IReviewsPayload;
    [REVIEWS_SEARCH_CLICKED]: IReviewsPayload;
    [REVIEWS_FILTER_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWER_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWER_SUBMITTED]: IReviewsPayload;
    [ASK_AND_ANSWER_SEARCH_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWER_HELPFUL_YES_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWER_HELPFUL_NO_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWER_SHOW_MORE_CLICKED]: IReviewsPayload;
    [ASK_AND_ANSWERS_SORT_BY_CHANGED]: IReviewsPayload;
    [WRITE_REVIEW_STARTED]: IReviewsPayload;
    [REVIEW_OVERLAY_OPENED]: IReviewsPayload;
    [REVIEW_OVERLAY_CLOSED]: IReviewsPayload;
    [REVIEWS_RATING_CLICKED]: IReviewsPayload;
    [REVIEWS_PAGINATION_CLICKED]: IReviewsPayload;
};
