export const GET_LEGACY_GUEST_AUTH_TOKEN = 'get.legacy.guestAuthtoken';
export const GET_GUEST_AUTH_TOKEN = 'get.guestAuthtoken';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const GET_ACCESS_TOKEN = 'get.accessToken';
export const USER_ID = 'userId';

export type ServiceSessionMgmtQueries = {
    [GET_LEGACY_GUEST_AUTH_TOKEN]: undefined;
    [GET_GUEST_AUTH_TOKEN]: undefined;
    [GET_ACCESS_TOKEN]: undefined;
    [IS_AUTHENTICATED]: undefined;
    [USER_ID]: undefined;
};

export type ServiceSessionMgmtQueriesResponseType = {
    [GET_LEGACY_GUEST_AUTH_TOKEN]: Promise<string>;
    [GET_GUEST_AUTH_TOKEN]: Promise<string>;
    [GET_ACCESS_TOKEN]: Promise<string>;
    [IS_AUTHENTICATED]: boolean | '' | null;
    [USER_ID]: string;
};
