export const ORDER_TRACKING_SUBMITTED = 'order_tracking.submitted';
export const ORDER_TRACKING_INVALID_NUMBER = 'order_tracking.invalidnumber';
export const ORDER_TRACKING_SUCCESSFUL = 'order_tracking.successful';

export interface IOrderTrackingEventData {
    orderNumber: string;
}

export type ServiceOrderTrackingEvents = {
    [ORDER_TRACKING_SUBMITTED]: IOrderTrackingEventData;
    [ORDER_TRACKING_INVALID_NUMBER]: IOrderTrackingEventData;
    [ORDER_TRACKING_SUCCESSFUL]: undefined;
};
