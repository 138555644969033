export const LOYALTY_LOADMORE_LINK_CLICKED = 'loyalty.loadMoreClicked';
export const LOYALTY_ONLINE_ORDER_NUMBER_CLICKED = 'loyalty.orderNumberClicked';
export const LOYALTY_SHOP_NOW_CLICKED = 'loyalty.shopNowClicked';
export const LOYALTY_ALL_BENEFITS_CLICKED = 'loyalty.allBenefitsClicked';
export const LOYALTY_BENEFITS_REDEEM_CLICKED = 'loyalty.benefitsRedeemClicked';
export const LOYALTY_BENEFITS_REMOVE_CLICKED = 'loyalty.benefitsRemoveClicked';
export const LOYALTY_REWARDS_REDEEM_CLICKED = 'loyalty.rewardsRedeemClicked';
export const LOYALTY_REWARDS_REMOVE_CLICKED = 'loyalty.rewardsRemoveClicked';
export const LOYALTY_REWARDS_TAB_CLICKED = 'loyalty.rewardsTabClicked';
export const LOYALTY_LINK_CLICKED = 'loyalty.linkClicked';

export declare type ServiceLoyaltyEvents = {
    [LOYALTY_LOADMORE_LINK_CLICKED]: void | undefined;
    [LOYALTY_ONLINE_ORDER_NUMBER_CLICKED]: string;
    [LOYALTY_SHOP_NOW_CLICKED]: string;
    [LOYALTY_ALL_BENEFITS_CLICKED]: string;
    [LOYALTY_BENEFITS_REDEEM_CLICKED]: string | null;
    [LOYALTY_BENEFITS_REMOVE_CLICKED]: string | null;
    [LOYALTY_REWARDS_REDEEM_CLICKED]: void | string;
    [LOYALTY_REWARDS_REMOVE_CLICKED]: void | string;
    [LOYALTY_REWARDS_TAB_CLICKED]: string;
    [LOYALTY_LINK_CLICKED]: string;
};
