export const COMPONENTS_LOADED = 'components.loaded';

export interface IComponentsLoaded {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any[];
}

export type ServiceComponentRendererEvents = {
    [COMPONENTS_LOADED]: IComponentsLoaded;
};
