export const AUTH_ATTEMPT = 'authentication.attempt';
export const AUTH_FAILED = 'authentication.failed';
export const AUTH_SUCCEDED = 'authentication.succeded';
export const FORGOT_PASSWORD_LINK_CLICK = 'forgot.password.link.click';
export const SIGNED_OUT = 'authentication.signed-out';
export const UNAUTHORIZED_REQUEST = 'app.elc-unauthorized-request';
export const SIGN_UP_LINK_CLICKED = 'sign.up.link.clicked';
export const REQUEST_CARE_AUTH = 'request.care.auth';

export type IStrategyConfig = {
    [key: string]: unknown;
};

export interface IRequestCareAuthPayload {
    context?: string;
    strategy?: string;
    redirectUrl?: string;
    strategies?: {
        [name: string]: IStrategyConfig;
    };
}

export interface IAuthSession {
    token: string;
    source: string;
    email: string;
}

export interface IPayloadSignUpClick {
    urlRedirectOnSuccess?: string;
}

export type ServiceSignInEvents = {
    [AUTH_ATTEMPT]: undefined;
    [AUTH_FAILED]: string;
    [AUTH_SUCCEDED]: IAuthSession | {};
    [FORGOT_PASSWORD_LINK_CLICK]: undefined;
    [SIGNED_OUT]: undefined;
    [UNAUTHORIZED_REQUEST]: undefined;
    [SIGN_UP_LINK_CLICKED]: IPayloadSignUpClick | void;
    [REQUEST_CARE_AUTH]: IRequestCareAuthPayload | undefined;
};
