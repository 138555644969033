export const SMARTGIFT_ADDED = 'smartGift.added';
export const SMARTGIFT_VIEWED = 'smartGift.viewed';
export const SMARTGIFT_WIDGET_TOGGLED = 'smartGift.widgetToggled';
export const SMARTGIFT_PREPARED = 'smartGift.prepared';
export const SMARTGIFT_REMOVED = 'smartGift.removed';
export const SMARTGIFT_CANCELLED = 'smartGift.cancelled';
export const SMARTGIFT_HELP_OPENED = 'smartGift.helpOpened';
export const SMARTGIFT_HELP_CLOSED = 'smartGift.helpClosed';

interface ISmartGiftPayload {
    productId: string;
}

export type ServiceSmartGiftEvents = {
    [SMARTGIFT_ADDED]: ISmartGiftPayload;
    [SMARTGIFT_VIEWED]: ISmartGiftPayload;
    [SMARTGIFT_WIDGET_TOGGLED]: ISmartGiftPayload;
    [SMARTGIFT_PREPARED]: ISmartGiftPayload;
    [SMARTGIFT_REMOVED]: ISmartGiftPayload;
    [SMARTGIFT_CANCELLED]: ISmartGiftPayload;
    [SMARTGIFT_HELP_OPENED]: ISmartGiftPayload;
    [SMARTGIFT_HELP_CLOSED]: ISmartGiftPayload;
};
