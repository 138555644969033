import * as ElcEcommEvents from './elc-ecomm';
import * as ElcServiceAccountEvents from './fe-elc-service-account';
import * as ELCServiceAppointmentBookingEvents from './fe-elc-service-appt-booking';
import * as ElcServiceCartEvents from './fe-elc-service-cart';
import * as ElcServiceCheckoutEvents from './fe-elc-service-checkout';
import * as ElcServiceCommunicationsEvents from './fe-elc-service-communications';
import * as ElcServiceComponentRendererEvents from './fe-elc-component-renderer';
import * as ElcServiceForgotPasswordEvents from './fe-elc-service-forgot-password';
import * as ElcServiceOffersEvents from './fe-elc-service-offers';
import * as ElcServiceOrderHistoryEvents from './fe-elc-service-order-history';
import * as ElcServiceOrderEvents from './fe-elc-service-order';
import * as ElcServicePasswordStrengthEvents from './fe-elc-service-password-strength';
import * as ElcServicePaymentEvents from './fe-elc-service-payment';
import * as ElcServiceProdcatEvents from './fe-elc-service-prodcat';
import * as ElcServiceSaveForLaterEvents from './fe-elc-service-save-for-later';
import * as ElcServiceSearchEvents from './fe-elc-service-search';
import * as ElcServiceSignInEvents from './fe-elc-service-sign-in';
import * as ElcServiceSignUpEvents from './fe-elc-service-sign-up';
import * as ElcServiceStoresEvents from './fe-elc-service-stores';
import * as ElcServiceUserProfileEvents from './fe-elc-service-user-profile';
import * as ElcServiceAnalyticsEvents from './fe-elc-service-analytics';
import * as ElcServiceVtoEvents from './fe-elc-service-vto';
import * as ElcServiceSmartGiftEvents from './fe-elc-service-smartgift';
import * as ElcServiceRatingsReviewsEvents from './fe-elc-service-ratings-reviews';
import * as ElcCareEvents from './elc-care';
import * as ElcRampEvents from './elc-ramp';
import * as ElcVideoEvents from './elc-video';
import * as ElcServiceContentEvents from './fe-elc-service-content';
import * as PgServiceOrderTrackingEvents from './fe-pg-service-order-tracking';
import * as ElcReactCheckoutAppEvents from './fe-elc-react-checkout-app';
import * as CartOverlayEvents from './cart';
import * as PgServiceRampEvents from './fe-pg-service-ramp';
import * as ElcServiceLoyaltyEvents from './fe-pg-service-loyalty';

const events = {
    ...ElcEcommEvents,
    ...ElcServiceAccountEvents,
    ...ELCServiceAppointmentBookingEvents,
    ...ElcServiceCartEvents,
    ...ElcServiceCheckoutEvents,
    ...ElcServiceCommunicationsEvents,
    ...ElcServiceComponentRendererEvents,
    ...ElcServiceForgotPasswordEvents,
    ...ElcServiceOffersEvents,
    ...ElcServiceOrderHistoryEvents,
    ...ElcServiceOrderEvents,
    ...ElcServicePasswordStrengthEvents,
    ...ElcServicePaymentEvents,
    ...ElcServiceProdcatEvents,
    ...ElcServiceSaveForLaterEvents,
    ...ElcServiceSearchEvents,
    ...ElcServiceSignInEvents,
    ...ElcServiceSignUpEvents,
    ...ElcServiceStoresEvents,
    ...ElcServiceUserProfileEvents,
    ...ElcServiceAnalyticsEvents,
    ...ElcServiceVtoEvents,
    ...ElcServiceSmartGiftEvents,
    ...ElcServiceRatingsReviewsEvents,
    ...ElcCareEvents,
    ...ElcRampEvents,
    ...ElcVideoEvents,
    ...ElcServiceContentEvents,
    ...PgServiceOrderTrackingEvents,
    ...ElcReactCheckoutAppEvents,
    ...CartOverlayEvents,
    ...PgServiceRampEvents,
    ...ElcServiceLoyaltyEvents
};

type ServiceEvents = ElcServiceCartEvents.ServiceCartEvents &
    ElcEcommEvents.ServiceElcEcommEvents &
    ElcServiceAccountEvents.ServiceAccountEvents &
    ELCServiceAppointmentBookingEvents.ServiceAppointmentBookingEvents &
    ElcServiceCheckoutEvents.ServiceCheckoutEvents &
    ElcServiceCommunicationsEvents.ServiceCommunicationsEvents &
    ElcServiceComponentRendererEvents.ServiceComponentRendererEvents &
    ElcServiceForgotPasswordEvents.ServiceForgotPasswordEvents &
    ElcServiceOffersEvents.ServiceOffersEvents &
    ElcServiceOrderHistoryEvents.ServiceOrderHistoryEvents &
    ElcServiceOrderEvents.ServiceOrderEvents &
    ElcServicePasswordStrengthEvents.ServicePasswordStrengthEvents &
    ElcServicePaymentEvents.ServicePaymentEvents &
    ElcServiceProdcatEvents.ServiceProdcatEvents &
    ElcServiceSaveForLaterEvents.ServiceSaveForLaterEvents &
    ElcServiceSearchEvents.ServiceSearchEvents &
    ElcServiceSignInEvents.ServiceSignInEvents &
    ElcServiceSignUpEvents.ServiceSignUpEvents &
    ElcServiceStoresEvents.ServiceStoresEvents &
    ElcServiceUserProfileEvents.ServiceUserProfileEvents &
    ElcServiceAnalyticsEvents.ServiceAnalyticsEvents &
    ElcServiceVtoEvents.ServiceVtoEvents &
    ElcServiceSmartGiftEvents.ServiceSmartGiftEvents &
    ElcServiceRatingsReviewsEvents.ServiceRatingsReviewsEvents &
    ElcCareEvents.CareEvents &
    ElcRampEvents.RampEvents &
    ElcVideoEvents.VideoEvents &
    ElcServiceContentEvents.ServiceContentEvents &
    PgServiceOrderTrackingEvents.ServiceOrderTrackingEvents &
    ElcReactCheckoutAppEvents.ReactCheckoutAppEvents &
    CartOverlayEvents.CartOverlayEvents &
    PgServiceRampEvents.ServiceIamEvents &
    ElcServiceLoyaltyEvents.ServiceLoyaltyEvents;

// eslint-disable-next-line no-restricted-syntax
export { events, ServiceEvents };
