/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELCLogger, TriggerType } from '@estee/elc-logging';
import { IQueryProvider, ServiceBus } from './ServiceBus';

const Logger = new ELCLogger({ serviceName: 'elc-service-bus' });

export const ServiceBusLogHook = (serviceBus: ServiceBus<any, any, any, any, any>) => ({
    beforeEventListenerRegister: (event: string, listener: Function, next: Function) => {
        Logger.event({
            triggerType: TriggerType.serviceBus,
            message: `beforeEventListenerRegister ${event}`
        });
        next(event, listener);
    },

    beforeEmit: (action: string, payload: any, next: Function) => {
        Logger.event({
            triggerType: TriggerType.serviceBus,
            payload,
            message: `beforeEmit ${action}`
        });
        next(action, payload);
    },

    afterEmit: (event: string, payload?: any) => {
        Logger.event({
            triggerType: TriggerType.serviceBus,
            payload,
            message: `afterEmit ${event}`
        });
    },

    beforeQueryRegister: (
        queryName: string,
        provider: IQueryProvider,
        context?: Record<string, any>,
        next?: Function
    ) => {
        Logger.query({
            triggerType: TriggerType.serviceBus,
            message: `beforeQueryRegister ${queryName}`
        });

        if (next) {
            next(queryName, provider, context);
        }
    },

    beforeQuery: (queryName: string, payload: any, next: Function) => {
        Logger.query({
            triggerType: TriggerType.serviceBus,
            payload,
            message: `beforeQuery ${queryName}`
        });

        return next(queryName, payload);
    },

    afterQuery: (queryName: string, result: any) => {
        Logger.query({
            triggerType: TriggerType.serviceBus,
            payload: result,
            message: `afterQuery ${queryName}`
        });
    }
});
