/* eslint-disable no-restricted-syntax */
import { events, ServiceEvents } from './service-bus-topics/events';
import { getDecorators as getPubSubDecorators } from './ServiceBusDecorators';

import {
    serviceQueries,
    ServiceQueries,
    ServiceQueriesResponseType
} from './service-bus-topics/queries';
import {
    serviceCommands,
    ServiceCommands,
    ServiceCommandsReturnType
} from './service-bus-topics/commands';
import { root, setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceBus } from './ServiceBus';
import { ServiceBusLogHook } from './ServiceBusLogHook';

setupServiceInfo(__serviceInfo__);

if (root) {
    // @ts-ignore
    root.GlobalServiceBus = new ServiceBus<
        ServiceEvents,
        ServiceQueries,
        ServiceQueriesResponseType,
        ServiceCommands,
        ServiceCommandsReturnType
    >();

    root.ServiceBusTopics = {
        commands: serviceCommands,
        events,
        queries: serviceQueries
    };
}

// @ts-ignore
const GlobalServiceBus: ServiceBus<
    ServiceEvents,
    ServiceQueries,
    ServiceQueriesResponseType,
    ServiceCommands,
    ServiceCommandsReturnType
> = root.GlobalServiceBus;

const {
    command,
    commandProvider,
    query,
    watchQuery,
    queryProvider,
    emit,
    on
} = getPubSubDecorators<
    ServiceEvents,
    ServiceQueries,
    ServiceQueriesResponseType,
    ServiceCommands,
    ServiceCommandsReturnType
>(GlobalServiceBus);

export {
    GlobalServiceBus,
    command,
    commandProvider,
    query,
    watchQuery,
    queryProvider,
    emit,
    on,
    serviceQueries,
    ServiceEvents,
    ServiceQueries,
    ServiceQueriesResponseType,
    ServiceCommands,
    ServiceCommandsReturnType,
    serviceCommands,
    events,
    ServiceBusLogHook
};

const ElcServiceBus = {
    GlobalServiceBus,
    command,
    commandProvider,
    query,
    watchQuery,
    queryProvider,
    emit,
    on,
    serviceQueries,
    serviceCommands,
    events,
    ServiceBusLogHook
};

export { ElcServiceBus };

export { ServiceBus, IQueryProvider, LogTypes } from './ServiceBus';
export { getDecorators } from './ServiceBusDecorators';
