export const SUBSCRIBED_FOR_EMAILS = 'subscribed.for.emails';
export const PRODUCT_NOTIFY_ME_REQUEST = 'product_notify.request';
export const PRODUCT_NOTIFY_ME_REQUEST_SUCCESS = 'product_notify.request.success';
export const PRODUCT_NOTIFY_ME_REQUEST_FAILURE = 'product_notify.request.failure';
export const PRODUCT_NOTIFY_ME_TRIGGERED = 'product_notify.triggered';

export interface IProductNotifyMeTriggered {
    skuId: string;
    skuBaseId: string;
}

export interface IProductNotifyMeRequestData {
    context?: string;
    skuId: string;
    skuBaseId: string;
    email: string;
    subscriptionType: string;
}

//eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProductNotifyMeRequestSuccessData extends IProductNotifyMeRequestData {}

export interface IProductNotifyMeRequestFailureData extends IProductNotifyMeRequestData {
    error?: string;
}

export type ServiceCommunicationsEvents = {
    [SUBSCRIBED_FOR_EMAILS]: undefined;
    [PRODUCT_NOTIFY_ME_REQUEST]: IProductNotifyMeRequestData;
    [PRODUCT_NOTIFY_ME_REQUEST_SUCCESS]: IProductNotifyMeRequestSuccessData;
    [PRODUCT_NOTIFY_ME_REQUEST_FAILURE]: IProductNotifyMeRequestFailureData;
    [PRODUCT_NOTIFY_ME_TRIGGERED]: IProductNotifyMeTriggered;
};
