export const CART_OVERLAY_OPENED = 'cart.overlay.opened';
export const CART_OVERLAY_CLOSED = 'cart.overlay.closed';
export const CART_OVERLAY_ICON_CLICKED = 'cart.overlay.icon_click';
export const CART_OVERLAY_TAB_CLICKED = 'cart.overlay.tab.clicked';
export const CART_OVERLAY_OFFER_VIEWED = 'cart.overlay.offer.viewed';
export const CART_OVERLAY_OFFER_CLICKED = 'cart.overlay.offer.clicked';

export interface CartOverlayEvents {
    [CART_OVERLAY_OPENED]: { nonInteraction: boolean };
    [CART_OVERLAY_CLOSED]: { nonInteraction: boolean };
    [CART_OVERLAY_ICON_CLICKED]: void | undefined;
    [CART_OVERLAY_TAB_CLICKED]: { tabId: string };
    [CART_OVERLAY_OFFER_VIEWED]: { placement: string; promotionId: string; promotionName: string };
    [CART_OVERLAY_OFFER_CLICKED]: { placement: string; promotionId: string; promotionName: string };
}
