export const SHIPPING_ADDRESS_FILLED = 'shipping.address.filled';
export const ACCOUNT_SETTINGS_LINK_CLICK = 'account.settings.link.click';
export const CREATE_ACCOUNT_SUCCESS = 'create.account.success';
export const LOGGED_IN_SUCCESSFUL = 'logged.in.successful';
export const USER_LOADED = 'user.loaded';
export const GUEST_FORM_VALIDITY_CHANGED = 'guest.form.validity.changed';
export const USER_PROFILE_CHANGED = 'user.profile.changed';
export const GET_OPTIN_PROFILE_LOAD = 'get.optin.profile.load';
export const SIGN_OUT_COMPLETED = 'sign.out.completed';
export const ADDRESS_SELECTION_CHANGED = 'address.selection.changed';
export const ADDRESS_SELECTION_VALIDITY_CHANGED = 'address.selection.validity.changed';
export const ADDRESS_EDIT_CHANGED = 'address.edit.changed';
export const ADDRESS_EDIT_VALIDITY_CHANGED = 'address.edit.validity.changed';
export const FAVORITES_LOADED = 'user.favorites.loaded';
export const FAVORITE_SELECTED = 'user.favorite.selected';
export const FAVORITE_STATUS_UPDATED = 'user.favorite.status.updated';

export interface IAddressAttributes {
    [field: string]: string;
}

export interface IAddress {
    id: string;
    isDefault?: boolean;
    addressMetadataId: string;
    attributes: IAddressAttributes;
}

export interface IUserProfileChangedEventData {
    email: string;
}

export interface IOptionProfileLoadEventData {
    email: string;
    source: string;
}

export interface IValidityChangedEventData {
    isValid: boolean;
}

export interface IAddressSelectionValidityChangedEventData {
    isValid: boolean;
    context: string;
}

export interface IAddressSelectionChangedEventData {
    context: string;
    address: IAddress | null;
    isNew?: boolean;
}

export interface IAddressEditChangedEventData {
    context: string;
    address: IAddress;
}

export interface IAddressEditValidityChangedEventData {
    context: string;
    isValid: boolean;
}

export interface IFavoriteSelectedEventData {
    skuBaseId: number;
}

export interface IFavoritesLoadedEventData {
    skuBaseIds: number[];
}

export interface IFavoriteStatusUpdatedEventData {
    skuBaseId: number;
    isFavorite?: boolean;
    isAlreadyAdded?: boolean;
}

export type ServiceUserProfileEvents = {
    [ACCOUNT_SETTINGS_LINK_CLICK]: undefined;
    [CREATE_ACCOUNT_SUCCESS]: undefined;
    [LOGGED_IN_SUCCESSFUL]: undefined;
    [USER_LOADED]: undefined;
    [GUEST_FORM_VALIDITY_CHANGED]: IValidityChangedEventData;
    [USER_PROFILE_CHANGED]: IUserProfileChangedEventData;
    [SIGN_OUT_COMPLETED]: undefined;
    [GET_OPTIN_PROFILE_LOAD]: IOptionProfileLoadEventData;
    [ADDRESS_SELECTION_CHANGED]: IAddressSelectionChangedEventData;
    [ADDRESS_SELECTION_VALIDITY_CHANGED]: IAddressSelectionValidityChangedEventData;
    [ADDRESS_EDIT_CHANGED]: IAddressEditChangedEventData;
    [ADDRESS_EDIT_VALIDITY_CHANGED]: IAddressEditValidityChangedEventData;
    [FAVORITES_LOADED]: IFavoritesLoadedEventData;
    [FAVORITE_SELECTED]: IFavoriteSelectedEventData;
    [FAVORITE_STATUS_UPDATED]: IFavoriteStatusUpdatedEventData;
};
