export const SERVICE_STARTED = 'service.started';
export const ECOMM_STARTED = 'ecomm.started';
export const NAVIGATE = 'navigate';
export const SESSION_EXPIRED = 'session.expired';
export const NAVIGATE_EXTERNAL_URL = 'navigate.external.url';
export const PRODUCT_OUT_OF_STOCK = 'product.out.of.stock';
export const ORDER_PAYMENT_CONFIRMED = 'order.payment.confirmed';
export const GUEST_ORDER_PAYMENT_CONFIRMED = 'guest.order.payment.confirmed';
export const REQUEST_LOGIN_AND_ASSOCIATE_ORDER = 'request.login.associate.order';
export const HOME_PAGE_LOADED = 'home.page.loaded';
export const NAVIGATE_APPT_BOOKING_REQUESTED = 'navigate.appt_booking.requested';
export const NAVIGATE_STORES_REQUESTED = 'mavigate.stores.requested';
export const NAVIGATE_LOCATION_REQUESTED = 'navigate.location.requested';

export type IStringMap = {
    [key: string]: string;
};

export interface INavigate {
    currentRoute: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: Record<string, any> | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams: Record<string, any> | undefined;
}

export interface IOrderPaymentConfirmedPayload {
    orderId: string;
}

export interface IGuestOrderPaymentConfirmedPayload {
    orderId: string;
    guestToken: string;
}

export interface IRequestNavigateLocationRequested {
    location: string;
    queryParams?: IStringMap;
}

export interface IRequestLoginAndAssociateOrderEventData {
    orderId: string;
    guestToken: string;
}

export interface IRequestNavigateApptBookingPayload {
    counterId?: string;
}

export interface IServiceStarted {
    serviceName: string;
}

export type ServiceElcEcommEvents = {
    [SERVICE_STARTED]: IServiceStarted;
    [ECOMM_STARTED]: undefined;
    [NAVIGATE]: INavigate;
    [SESSION_EXPIRED]: undefined;
    [NAVIGATE_EXTERNAL_URL]: string;
    [PRODUCT_OUT_OF_STOCK]: undefined;
    [ORDER_PAYMENT_CONFIRMED]: IOrderPaymentConfirmedPayload;
    [GUEST_ORDER_PAYMENT_CONFIRMED]: IGuestOrderPaymentConfirmedPayload;
    [REQUEST_LOGIN_AND_ASSOCIATE_ORDER]: IRequestLoginAndAssociateOrderEventData;
    [HOME_PAGE_LOADED]: undefined;
    [NAVIGATE_LOCATION_REQUESTED]: IRequestNavigateLocationRequested;
    [NAVIGATE_APPT_BOOKING_REQUESTED]: IRequestNavigateApptBookingPayload;
    [NAVIGATE_STORES_REQUESTED]: undefined;
};
