export const SEARCH_PRODUCTS_QUERIED = 'search.products.queried';
export const SEARCH_TERM_SELECTED = 'search.term.selected';
export const SEARCH_OVERLAY_DISPLAYED = 'search.overlay.displayed';
export const SEARCH_FILTERS_SELECTED = 'search.filters.selected';
export const SEARCH_SHOULD_RENDER = 'search.should.render';
export const SEARCH_ICON_HIDE = 'search.icon.hide';

export interface ISearchProductsQueried {
    searchTerm: string;
    searchType?: string;
    skuIds?: string[];
}

export interface ISearchTermSelected {
    searchTerm: string;
}

export interface ISearchOverlayDisplayed {
    promoItemNumber?: string;
    promoItemName?: string;
}

export interface ISearchFiltersSelected {
    facetId: string;
    responseId?: string;
    filterCategory?: string;
    filterLabel?: string;
}

export type ServiceSearchEvents = {
    [SEARCH_PRODUCTS_QUERIED]: ISearchProductsQueried;
    [SEARCH_TERM_SELECTED]: ISearchTermSelected;
    [SEARCH_OVERLAY_DISPLAYED]: ISearchOverlayDisplayed;
    [SEARCH_FILTERS_SELECTED]: ISearchFiltersSelected;
    [SEARCH_SHOULD_RENDER]: undefined;
    [SEARCH_ICON_HIDE]: undefined;
};