export const EMAIL_OPT_IN = 'email.opt.in';

export interface IEmailOptIn {
    communicationType: string;
    emailAddress: string;
    emailSubscribed: boolean;
    id: string;
    source: string;
}

export type ServiceCommunicationsQueriesResponseType = {
    [EMAIL_OPT_IN]: undefined;
};

export type ServiceCommunicationsQueries = {
    [EMAIL_OPT_IN]: IEmailOptIn;
};
