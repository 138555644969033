export const STORE_LOCATION_SELECTED = 'store.location.selected';
export const STORE_TOWN_SELECTED = 'store.town.selected';
export const APPOINTMENT_BOOKED = 'appointment.booked';
export const APPOINTMENT_STEP_LOADED = 'appointment.step.loaded';
export const APPOINTMENT_SERVICE_SELECTED = 'appointment.service.selected';
export const APPOINTMENT_DATE_TIME_SELECTED = 'appointment.date.time.selected';
export const APPOINTMENT_ARTIST_SELECTED = 'appointment.artist.selected';
export const APPOINTMENT_EDIT_CLICKED = 'appointment.edit.clicked';
export const APPOINTMENT_STARTED_OVER = 'appointment.started.over';
export const APPOINTMENT_ADDED_TO_CALENDAR = 'appointment.added.to.calendar';
export const APPOINTMENT_VIRTUAL_SELECTED = 'appointment.virtual.selected';
export const APOOINTMENT_PROCESS_COMPLETED = 'appointment.process.completed';
export const APPOINTMENT_LOCATION_SELECTED = 'appointment.location.selected';
export const APPOINTMENT_REVIEW_WIDGET_CLICK = 'appointment.review.widget.click';
export const APPOINTMENT_REVIEW_PAGINATION = 'appointment.review.pagination';
export const APPOINTMENT_REVIEW_WIDGET_FILTERS = 'appointment.review.widget.filters';
export const APPOINTMENT_ACCOUNT_BUTTON_CLICKED = 'appointment.account.button.clicked';
export const APPOINTMENT_CALL_STORE_CLICKED = 'appointment.call.store.clicked';

export interface IStoreLocationSelectionData {
    storeId?: string;
}

export interface IStoreTownSelectionData {
    town?: string;
}

export interface IAppointmentBookedData {
    email: string;
    source: string;
}

export interface IAppointmentBookingStepLoadData {
    step: string;
}

export interface IAppointmentLocationData {
    storeName?: string;
}

export interface IAppointmentServiceData {
    serviceName: string[];
}

export interface IAppointmentDateTimeData {
    dateTime: string;
}

export interface IAppointmentArtistData {
    artistName: string;
}

export interface IAppointmentReviewWidgetClick {
    serviceName: string;
}

export interface IAppointmentReviewPagination {
    optionSelection: string;
}

export interface IAppointmentReviewWidgetFilters extends IAppointmentReviewWidgetClick {
    location: string;
    sortBy: string;
}

export interface IAppointmentAccountButtonClicked {
    accountButtonLabel: string;
}

export interface IAppointmentCallStoreClicked {
    storeName: string;
}

export type ServiceAppointmentBookingEvents = {
    [STORE_LOCATION_SELECTED]: IStoreLocationSelectionData | void;
    [STORE_TOWN_SELECTED]: IStoreTownSelectionData | void;
    [APPOINTMENT_LOCATION_SELECTED]: IAppointmentLocationData;
    [APPOINTMENT_BOOKED]: IAppointmentBookedData;
    [APPOINTMENT_STEP_LOADED]: IAppointmentBookingStepLoadData;
    [APPOINTMENT_SERVICE_SELECTED]: IAppointmentServiceData;
    [APPOINTMENT_DATE_TIME_SELECTED]: IAppointmentDateTimeData;
    [APPOINTMENT_ARTIST_SELECTED]: IAppointmentArtistData;
    [APPOINTMENT_EDIT_CLICKED]: void;
    [APPOINTMENT_STARTED_OVER]: void;
    [APPOINTMENT_ADDED_TO_CALENDAR]: void;
    [APPOINTMENT_VIRTUAL_SELECTED]: void;
    [APOOINTMENT_PROCESS_COMPLETED]: void;
    [APPOINTMENT_REVIEW_WIDGET_CLICK]: IAppointmentReviewWidgetClick;
    [APPOINTMENT_REVIEW_PAGINATION]: IAppointmentReviewPagination;
    [APPOINTMENT_REVIEW_WIDGET_FILTERS]: IAppointmentReviewWidgetFilters;
    [APPOINTMENT_ACCOUNT_BUTTON_CLICKED]: IAppointmentAccountButtonClicked;
    [APPOINTMENT_CALL_STORE_CLICKED]: IAppointmentCallStoreClicked;
};
