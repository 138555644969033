import * as ElcEcommQueries from './fe-elc-ecomm';
import * as ElcServiceAnalyticsQueries from './fe-elc-service-analytics';
import * as ElcServiceCartQueries from './fe-elc-service-cart';
import * as ElcServiceCheckoutQueries from './fe-elc-service-checkout';
import * as ElcServiceCommunicationsQueries from './fe-elc-service-communications';
import * as ElcServiceOffersQueries from './fe-elc-service-offers';
import * as ElcServiceOrderHistoryQueries from './fe-elc-service-order-history';
import * as ElcServiceOrderQueries from './fe-elc-service-order';
import * as ElcServicePasswordStrengthQueries from './fe-elc-service-password-strength';
import * as ElcServicePaymentQueries from './fe-elc-service-payment';
import * as ElcServiceProdcatQueries from './fe-elc-service-prodcat';
import * as ElcServiceSaveForLaterQueries from './fe-elc-service-save-for-later';
import * as ElcServiceSignUpQueries from './fe-elc-service-sign-up';
import * as ElcServiceUserProfileQueries from './fe-elc-service-user-profile';
import * as ElcServiceConfigManagerQueries from './fe-elc-service-config-manager';
import * as ElcServiceTranslationsQueries from './fe-elc-service-translations';
import * as ElcServiceStoreQueries from './fe-elc-service-stores';
import * as ElcServiceApptBookingQueries from './fe-elc-service-appt-booking';
import * as ElcServiceLegalQueries from './fe-elc-service-legal';
import * as ElcServiceContentQueries from './fe-elc-service-content';
import * as ElcServiceRatingsReviewsQueries from './fe-elc-service-ratings-reviews';
import * as ElcServiceVtoQueries from './fe-elc-service-vto';
import * as ElcServiceSessionMgmtQueries from './fe-elc-service-session-mgmt';
import * as ElcServiceLoyaltyQueries from './fe-pg-service-loyalty';

const serviceQueries = {
    ...ElcEcommQueries,
    ...ElcServiceAnalyticsQueries,
    ...ElcServiceCartQueries,
    ...ElcServiceCheckoutQueries,
    ...ElcServiceCommunicationsQueries,
    ...ElcServiceOffersQueries,
    ...ElcServiceOrderHistoryQueries,
    ...ElcServiceOrderQueries,
    ...ElcServicePasswordStrengthQueries,
    ...ElcServicePaymentQueries,
    ...ElcServiceProdcatQueries,
    ...ElcServiceSaveForLaterQueries,
    ...ElcServiceSignUpQueries,
    ...ElcServiceUserProfileQueries,
    ...ElcServiceConfigManagerQueries,
    ...ElcServiceTranslationsQueries,
    ...ElcServiceStoreQueries,
    ...ElcServiceApptBookingQueries,
    ...ElcServiceLegalQueries,
    ...ElcServiceContentQueries,
    ...ElcServiceRatingsReviewsQueries,
    ...ElcServiceVtoQueries,
    ...ElcServiceSessionMgmtQueries,
    ...ElcServiceLoyaltyQueries
};

type ServiceQueries = ElcServiceCartQueries.ServiceCartQueries &
    ElcEcommQueries.ServiceElcEcommQueries &
    ElcServiceAnalyticsQueries.ServiceAnalyticsQueries &
    ElcServiceCheckoutQueries.ServiceCheckoutQueries &
    ElcServiceCommunicationsQueries.ServiceCommunicationsQueries &
    ElcServiceOffersQueries.ServiceOffersQueries &
    ElcServiceOrderHistoryQueries.ServiceOrderHistoryQueries &
    ElcServiceOrderQueries.ServiceOrderQueries &
    ElcServicePasswordStrengthQueries.ServicePasswordStrengthQueries &
    ElcServicePaymentQueries.ServicePaymentQueries &
    ElcServiceProdcatQueries.ServiceProdcatQueries &
    ElcServiceSaveForLaterQueries.ServiceSaveForLaterQueries &
    ElcServiceSessionMgmtQueries.ServiceSessionMgmtQueries &
    ElcServiceSignUpQueries.ServiceSignUpQueries &
    ElcServiceTranslationsQueries.ServiceTranslationsQueries &
    ElcServiceConfigManagerQueries.ServiceConfigManagerQueries &
    ElcServiceUserProfileQueries.ServiceUserProfileQueries &
    ElcServiceLoyaltyQueries.ServiceLoyaltyQueries &
    ElcServiceStoreQueries.ServiceStoreInventoryQueries &
    ElcServiceApptBookingQueries.ServiceApptBookingQueries &
    ElcServiceLegalQueries.ServiceLegalQueries &
    ElcServiceContentQueries.ServiceContentTemplatesQueries &
    ElcServiceRatingsReviewsQueries.ServiceRatingsReviewsQueries &
    ElcServiceVtoQueries.ServiceVtoQueries;
type ServiceQueriesResponseType = ElcServiceCartQueries.ServiceCartQueriesResponseType &
    ElcEcommQueries.ServiceElcEcommQueriesResponseType &
    ElcServiceAnalyticsQueries.ServiceAnalyticsQueriesResponseType &
    ElcServiceCheckoutQueries.ServiceCheckoutQueriesResponseType &
    ElcServiceCommunicationsQueries.ServiceCommunicationsQueriesResponseType &
    ElcServiceOffersQueries.ServiceOffersQueriesResponseType &
    ElcServiceOrderHistoryQueries.ServiceOrderHistoryQueriesResponseType &
    ElcServiceOrderQueries.ServiceOrderQueriesResponseType &
    ElcServicePasswordStrengthQueries.ServicePasswordStrengthQueriesResponseType &
    ElcServicePaymentQueries.ServicePaymentQueriesResponseType &
    ElcServiceProdcatQueries.ServiceProdcatQueriesResponseType &
    ElcServiceSaveForLaterQueries.ServiceSaveForLaterQueriesResponseType &
    ElcServiceSessionMgmtQueries.ServiceSessionMgmtQueriesResponseType &
    ElcServiceSignUpQueries.ServiceSignUpQueriesResponseType &
    ElcServiceTranslationsQueries.ServiceTranslationsQueriesResponseType &
    ElcServiceConfigManagerQueries.ServiceConfigManagerQueriesResponseType &
    ElcServiceUserProfileQueries.ServiceUserProfileQueriesResponseType &
    ElcServiceLoyaltyQueries.ServiceLoyaltyQueriesResponseType &
    ElcServiceStoreQueries.ServiceStoreInventoryQueriesResponseType &
    ElcServiceApptBookingQueries.ServiceApptBookingQueriesResponseType &
    ElcServiceLegalQueries.ServiceLegalQueriesResponseType &
    ElcServiceContentQueries.ServiceContentTemplatesQueriesResponseType &
    ElcServiceRatingsReviewsQueries.ServiceRatingsReviewsQueriesResponseType &
    ElcServiceVtoQueries.ServiceVtoQueriesResponseType;
// eslint-disable-next-line no-restricted-syntax
export { serviceQueries, ServiceQueries, ServiceQueriesResponseType };
