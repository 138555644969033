export const TEMPLATES_CONTENT_GET = 'content.templates.get';

interface INodeTemplate {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [key: string]: any;
}

export type ServiceContentTemplatesQueries = {
    [TEMPLATES_CONTENT_GET]: { nodeId: number };
};

export type ServiceContentTemplatesQueriesResponseType = {
    [TEMPLATES_CONTENT_GET]: Promise<INodeTemplate[]>;
};
