export const SAVE_FOR_LATER_ADD_BACK_TO_CART = 'saveforlater.addItemBack';
export const SAVE_FOR_LATER_UPDATE_FAILED = 'saveforlater.updateFailed';
export interface ISaveForLaterAddBackToCart {
    skuId: string;
    quantity: number;
}

export type ServiceSaveForLaterEvents = {
    [SAVE_FOR_LATER_ADD_BACK_TO_CART]: ISaveForLaterAddBackToCart;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [SAVE_FOR_LATER_UPDATE_FAILED]: Record<string, any>;
};
