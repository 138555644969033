export const CONFIRM_REGISTRATION_TOKEN = 'confirm.registration.token';
export const CONFIRM_REGISTRATION_URL = 'confirm.registration.url';
export const RESET_PASSWORD_URL = 'reset.password.url';
export const GET_CURRENT_ROUTE = 'get.current.route';
export const CARE_HANDLER_URL = 'care.handler.url';

type ICurrentRoute = {
    currentRoute: string;
    params: { [key: string]: string };
    queryParams: { [key: string]: string };
};

export type ServiceElcEcommQueries = {
    [CONFIRM_REGISTRATION_TOKEN]: undefined;
    [CONFIRM_REGISTRATION_URL]: undefined;
    [CARE_HANDLER_URL]: undefined;
    [RESET_PASSWORD_URL]: undefined;
    [GET_CURRENT_ROUTE]: undefined;
};
export type ServiceElcEcommQueriesResponseType = {
    [CONFIRM_REGISTRATION_TOKEN]: string;
    [CONFIRM_REGISTRATION_URL]: string;
    [CARE_HANDLER_URL]: string;
    [RESET_PASSWORD_URL]: string;
    [GET_CURRENT_ROUTE]: ICurrentRoute;
};
