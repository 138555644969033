export const CART_ADD_ITEM = 'cart.addItem';
export const CART_CLEAR_ERRORS = 'cart.clearErrors';
export const CART_CLEAR = 'cart.clear';
export const CART_SET_SHIPPING_METHOD = 'cart.setShippingMethod';
export const CART_ADD_ADDRESSES = 'cart.add.addresses';
export const SAVE_FOR_LATER_ADD = 'cart.save.for.later';
export const CART_OVERLAY_SHOW = 'cart.overlay.show';
export const CART_OVERLAY_HIDE = 'cart.overlay.hide';
export interface IAddressAttributes {
    [key: string]: string;
}
export interface ICartAddress {
    addressMetadataId: string;
    attributes: IAddressAttributes;
}
export interface ICartAddAddress {
    shippingAddresses: ICartAddress[];
    billingAddress: ICartAddress;
}
export interface ISaveForLaterAdd {
    skuId: string;
    quantity: number;
}
export interface ISelectedShippingDates {
    [key: string]: string[];
}
export interface ICartMethod {
    shippingMethodId: string;
    selectedShippingDates: ISelectedShippingDates;
}

export type ServiceCartCommands = {
    [CART_ADD_ITEM]: { skuId: string; quantity?: number; replenishment?: number };
    [CART_CLEAR_ERRORS]: undefined;
    [CART_CLEAR]: undefined;
    [CART_SET_SHIPPING_METHOD]: ICartMethod;
    [CART_ADD_ADDRESSES]: ICartAddAddress;
    [SAVE_FOR_LATER_ADD]: ISaveForLaterAdd;
    [CART_OVERLAY_SHOW]: undefined;
    [CART_OVERLAY_HIDE]: undefined;
};

export type ServiceCartCommandsReturnType = {
    [CART_ADD_ITEM]: Promise<void>;
    [CART_CLEAR_ERRORS]: undefined;
    [CART_CLEAR]: undefined;
    [CART_SET_SHIPPING_METHOD]: undefined;
    [CART_ADD_ADDRESSES]: Promise<void>;
    [SAVE_FOR_LATER_ADD]: Promise<void>;
    [CART_OVERLAY_SHOW]: Promise<void>;
    [CART_OVERLAY_HIDE]: Promise<void>;
};
