export const VIDEO_READY = 'video.ready';
export const VIDEO_PLAY = 'video.play';
export const VIDEO_PAUSE = 'video.pause';
export const VIDEO_END = 'video.end';

export type VideoEvents = {
    [VIDEO_READY]: undefined;
    [VIDEO_PLAY]: undefined;
    [VIDEO_PAUSE]: undefined;
    [VIDEO_END]: undefined;
};
