export const FULL_NAME = 'fullName';
export const BASIC_USER_DETAILS = 'basicUserDetails';
export const IS_SIGN_IN_FORM_OPEN = 'isSignInFormOpen';
export const EMAIL_VERIFIED = 'isEmailVerified';
export const GUEST_EMAIL = 'guestEmail';
export const EMAIL = 'email';
export const BIRTH_DATE = 'birth.date';
export const GET_USER_CREATION_DATE = 'get.user.creation.date';
export const GET_FORMATTED_ADDRESS = 'get.user.formatted.address';
export const GET_USER_ACCOUNT_ID = 'get.user.account.id';
export const GET_USER_AUTH_STATE = 'getUserAuthState';

export interface IGetFormattedAddress {
    metadataId: string;
    attributes: {
        [field: string]: string;
    };
}

export interface IBasicUserDetails {
    emailAddress: string;
    firstName: string;
    lastName: string;
    hasLoyalty: boolean;
}

enum AuthStates {
    SIGNEDIN,
    ANONYMOUS,
    RECOGNIZED
}

export type UserAuthState = keyof typeof AuthStates;

export type ServiceUserProfileQueries = {
    [FULL_NAME]: undefined;
    [BASIC_USER_DETAILS]: undefined;
    [IS_SIGN_IN_FORM_OPEN]: undefined;
    [EMAIL_VERIFIED]: undefined;
    [GUEST_EMAIL]: undefined;
    [EMAIL]: undefined;
    [BIRTH_DATE]: undefined;
    [GET_USER_CREATION_DATE]: undefined;
    [GET_FORMATTED_ADDRESS]: IGetFormattedAddress;
    [GET_USER_ACCOUNT_ID]: undefined;
    [GET_USER_AUTH_STATE]: undefined;
};

export type ServiceUserProfileQueriesResponseType = {
    [FULL_NAME]: string;
    [BASIC_USER_DETAILS]: IBasicUserDetails;
    [IS_SIGN_IN_FORM_OPEN]: boolean;
    [EMAIL_VERIFIED]: boolean;
    [GUEST_EMAIL]: string;
    [EMAIL]: string;
    [BIRTH_DATE]: string;
    [GET_USER_CREATION_DATE]: string;
    [GET_FORMATTED_ADDRESS]: Promise<string>;
    [GET_USER_ACCOUNT_ID]: string;
    [GET_USER_AUTH_STATE]: UserAuthState;
};
