export const TRANSACTION_FAILED = 'transaction.failed';
export const TRANSACTION_SUCCEDED = 'transaction.succeded';
export const SHIPPING_SCREEN_FAILURE = 'shipping.screen.failure';
export const PAYMENT_STRATEGY_CHANGED = 'payment.strategy.changed';
export const PAYMENT_STRATEGY_LOAD = 'payment.strategy.load';
export const PAYMENT_STRATEGY_LOAD_DONE = 'payment.strategy.load.done';
export const PAYMENT_STRATEGY_SAVE = 'payment.strategy.save';
export const PAYMENT_STRATEGY_SAVE_DONE = 'payment.strategy.save.done';
export const PAYMENT_STRATEGY_AVAILABILITY_SET = 'payment.strategy.availability.set';
export const PAYMENT_SUBMIT_REQUEST = 'payment.submit.request';

enum PaymentStatus {
    AUTHORIZED = 'AUTHORIZED',
    PENDING = 'PENDING',
    REFUSED = 'REFUSED',
    UNKNOWN = 'UNKNOWN'
}

interface IPaymentStrategyDetails {
    providerId: string;
    methodId: string;
}

export interface ITransactionSuccededPayload {
    orderId: string;
    guestToken: string;
    paymentType: string;
    orderNumber: string;
    amount: number;
    info: string;
    tokenId?: string;
    status: PaymentStatus;
    paymentUrl: string | null;
}

/**
 * Triggered when the user changes the payment strategy.
 */
export interface IPaymentStrategyChangedEventData {
    /**
     * The logical name for the payment strategy.
     * If null then no payment strategy has been set.
     */
    name: string | null;

    /**
     * The payment provider id and method id pair used for the strategy
     */
    details: IPaymentStrategyDetails | null;

    /**
     * If set, the label for the 'Continue' checkout button.
     */
    continueCheckoutLabel?: string;
}

export interface IPaymentStrategyLoadEventData {
    /**
     * The payment strategy name to restore to
     */
    name: string;

    /**
     * The load context
     */
    context: string;

    /**
     * Data used to restore the payment strategy
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export interface IPaymentStrategyLoadDoneEventData {
    /**
     * The payment strategy name
     */
    name: string;

    /**
     * The context
     */
    context: string;

    /**
     * Operation success
     */
    success: boolean;
}

export interface IPaymentStrategySaveEventData {
    /**
     * The name of the payment strategy to save
     */
    name: string;

    /**
     * The context for the save operation
     */
    context: string;
}

export interface IPaymentStrategySaveDoneEventData {
    /**
     * Payment strategy name
     */
    name: string;

    /**
     * Serialized data or null in case of failure
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;

    /**
     * Operation context
     */
    context: string;

    /**
     * Operation success
     */
    success: boolean;
}

export interface IPaymentSourceAvailability {
    name: string;
    enabled: boolean;
}

export interface IPaymentStrategyAvailabilitySetEventData {
    paymentSources: IPaymentSourceAvailability[];
}

export type ServicePaymentEvents = {
    [TRANSACTION_FAILED]: {};
    [TRANSACTION_SUCCEDED]: ITransactionSuccededPayload;
    [SHIPPING_SCREEN_FAILURE]: undefined;
    [PAYMENT_STRATEGY_CHANGED]: IPaymentStrategyChangedEventData;
    [PAYMENT_STRATEGY_LOAD]: IPaymentStrategyLoadEventData;
    [PAYMENT_STRATEGY_LOAD_DONE]: IPaymentStrategyLoadDoneEventData;
    [PAYMENT_STRATEGY_SAVE]: IPaymentStrategySaveEventData;
    [PAYMENT_STRATEGY_SAVE_DONE]: IPaymentStrategySaveDoneEventData;
    [PAYMENT_STRATEGY_AVAILABILITY_SET]: IPaymentStrategyAvailabilitySetEventData;
    [PAYMENT_SUBMIT_REQUEST]: undefined;
};
