export const FINDATION_BUTTON_CLICKED = 'findation.button.clicked';
export const BASIC_TOUT_LOADED = 'basic.tout.loaded';

export interface IFindationButtonClicked {
    findationKey: string;
}

export type ServiceContentEvents = {
    [FINDATION_BUTTON_CLICKED]: IFindationButtonClicked;
    [BASIC_TOUT_LOADED]: {};
};
