export const GET_PRODUCT_DATA = 'getProductData';
export const GET_PRODUCTS = 'getProducts';
export const GET_PRODUCTS_V2 = 'getProductsV2';
export const GET_PRODUCTS_V3 = 'getProductsV3';
export const GET_REVIEW_PRODUCT = 'getReviewProduct';
export const GET_SELECTED_FILTERS = 'get.selected.filters';
export const GET_SORTBY_VALUE = 'get.sortby.value';
export const GET_SKUS = 'getSkus';
export const GET_PRODCAT_INVENTORY = 'getProdcatInventory';
export const GET_INVENTORY = 'getInventory';

enum SortDirection {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING'
}

type SortDirectionType = keyof typeof SortDirection;
interface IGetProductDataOptions {
    filterBy: {
        tags?: {
            ids?: string[];
            keys?: string[];
            values?: string[];
            includeInnerHits?: boolean;
        };
        skus?: {
            inventoryStatus?: string[];
            skuIds?: string[];
            isDefaultSku?: boolean;
            shades?: {
                name: string[];
            };
            includeInnerHits?: boolean;
        };
        productIds?: string[];
    };
    sortBy?: {
        tags?: {
            productDisplayOrder: SortDirectionType;
        };
        skus?: {
            skuIds?: SortDirectionType;
            shades?: {
                name: SortDirectionType;
            };
        };
        productIds?: SortDirectionType;
    };
    query?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productGridConfig?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productFullConfig?: any;
}

export type ServiceProdcatQueries = {
    [GET_PRODUCT_DATA]: IGetProductDataOptions;
    [GET_PRODUCTS]: {};
    [GET_PRODUCTS_V2]: {};
    [GET_PRODUCTS_V3]: {};
    [GET_REVIEW_PRODUCT]: {};
    [GET_SELECTED_FILTERS]: {};
    [GET_SORTBY_VALUE]: {};
    [GET_SKUS]: {};
    [GET_PRODCAT_INVENTORY]: string[];
    [GET_INVENTORY]: string[];
};

export type ServiceProdcatQueriesResponseType = {
    [GET_PRODUCT_DATA]: {};
    [GET_PRODUCTS]: {};
    [GET_PRODUCTS_V2]: {};
    [GET_PRODUCTS_V3]: {};
    [GET_REVIEW_PRODUCT]: {};
    [GET_SELECTED_FILTERS]: {};
    [GET_SORTBY_VALUE]: {};
    [GET_SKUS]: {};
    [GET_PRODCAT_INVENTORY]: [];
    [GET_INVENTORY]: [];
};
